import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Table } from "react-bootstrap";
import SearchBar from "../SearchBar";
import { readProduct } from "../../../store/store";
import ModalWrapper from "../ModalWrapper";
import UpDateStack from "./UpdateStack";
import { useNavigate } from "react-router-dom";
import { destroy } from "../../../services/api";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import { checkProductOption } from "../../../other/options";

const ManageProduct = ({ dreadProduct, products }) => {
  console.log("product", products);

  const navigate = useNavigate();
  const [showUpdateStack, setShowUpdateStack] = useState(false);
  const [search, setsearch] = useState("");
  const [columnName, setColumnName] = useState([]);

  const onChangeSelect = (e) => {
    setColumnName(e.map((item) => item.value));
  };

  useEffect(() => {
    dreadProduct();
  }, []);

  const editProduct = (item) => {
    console.log(item);
    navigate(`/editProduct/${item.productid}`);
  };

  const deleteItem = (item) => {
    try {
      const { productid } = item;
      const prodId = productid;
      console.log("deleted", prodId);
      destroy("InventoryPost/delete_Product", prodId);
      dreadProduct();
      toast.success("Record has been Deleted");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const closeUpdateStack = () => {
    setShowUpdateStack(false);
  };
  const StackUpdate = (e) => {
    setShowUpdateStack(true);
  };

  const onSearchChange = (e) => {
    setsearch(e.target.value);
  };

  console.log(search);

  return (
    <>
      <div className="card ">
        <div className="card-header">
          <h3>Manage Product</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <SearchBar
              wrapperClass="col-md-8"
              placeholder="Search here..."
              label="Search here"
              searchText={search}
              onSearchChange={onSearchChange}
            />
            <div className="col-md-4 mb-3">
              <label className="form-label">Choose Item</label>
              <ReactSelect
                defaultValue
                isMulti
                options={checkProductOption}
                onChange={onChangeSelect}
              />
            </div>
          </div>

          <Table responsive className="w-100">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Sale Price</th>
                <th>Product Type</th>
                <th>Avilable Stock</th>

                {columnName.includes("productimage") && <th>Image</th>}
                {columnName.includes("categoryid") && <th>Category</th>}
                {columnName.includes("productcode") && <th>Product Code</th>}
                {columnName.includes("unitname") && <th>Unit</th>}

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!products &&
                products
                  .filter((items) =>
                    search.toLowerCase() === ""
                      ? items
                      : items.productname.toLowerCase().includes(search)
                  )
                  .map((item, index) => (
                    <tr key={`id${index}`}>
                      <td>{item.productname}</td>
                      <td>{item.saleprice}</td>
                      <td>{item.producttype}</td>
                      <td>6</td>

                      {columnName.includes("productimage") && (
                        <td>
                          <img
                            className="img-thumbnail"
                            src={item.productimage}
                            alt="product"
                          />
                        </td>
                      )}
                      {columnName.includes("categoryid") && (
                        <td>{item.categoryid}</td>
                      )}
                      {columnName.includes("productcode") && (
                        <td>{item.productcode}</td>
                      )}
                      {columnName.includes("unitname") && (
                        <td>{item.unitname}</td>
                      )}

                      <td>
                        <div className="basic-dropdown">
                          <Dropdown>
                            <Dropdown.Toggle variant="primary">
                              Dropdown button
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                as="button"
                                onClick={() => editProduct(item)}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => deleteItem(item)}
                              >
                                Detele
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={(e) => StackUpdate(e)}
                              >
                                Update Stack
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
              {!products && (
                <tr>
                  <td>
                    <td>No records found...</td>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <ModalWrapper
            show={showUpdateStack}
            handleClose={closeUpdateStack}
            component={UpDateStack}
            title={`Update Stack`}
          />
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  products: state.products.data.manageItemData,
});

const mapDispatch = (dispatch) => ({
  dreadProduct: (p) => dispatch(readProduct(p)),
});
export default connect(mapState, mapDispatch)(ManageProduct);

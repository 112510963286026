import { useState } from "react";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";

const RenderAsyncSelect = ({
  wrapperClass,
  input,
  label,

  placeholder,
  defaultOptions,
  options,
  labelClass,
  loadOptions,
  formControlType,
  isSearchable = false,
  disabled = false,
  onSelectChange,
  meta: { touched, error },
}) => {
  return (
    <>
      <div className={wrapperClass}>
        <label className={labelClass}>{label}</label>
        <AsyncSelect
          {...input}
          //   isMulti
          cacheOptions
          defaultOptions={options}
          loadOptions={loadOptions}
          isClearable
          // onChange={(value) => input.onChange(value)}
          //   option={options}
          onBlur={() => input.onBlur()}
          onChange={(v) => {
            input.onChange(v);
            onSelectChange && onSelectChange(v);
          }}
        />
        {touched && error && <span className="text-danger">{error}</span>}
      </div>
    </>
  );
};
export default RenderAsyncSelect;

import { Field, Form } from "react-final-form";
import RenderSelect from "../components/renderField/RenderSelect";
import RenderField from "../components/renderField/RenderField";

const Expense = () => {
  return (
    <>
      <div className="container-fluied">
        <div className="row">
          <Form
            initialValues={{}}
            // validate={{}}
            onSubmit={{}}
            render={({ handleSubmit, values, form }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="card h-auto">
                  <div className="card-header">
                    <h3>Expense</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Field
                        name="Expense Category"
                        // type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="ModeOfpayment"
                        labelClass="form-label"
                        label="Expense Category"
                        // inputClass="form-control"
                        isSearchable
                        options={[
                          { value: "Cash", label: "Add item" },
                          { value: "Cheque", label: "Add item" },
                        ]}
                        component={RenderSelect}
                      />
                      <Field
                        name="Expense Category"
                        // type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="ModeOfpayment"
                        labelClass="form-label"
                        label="Mode of Payment"
                        // inputClass="form-control"
                        isSearchable
                        options={[
                          { value: "Cash", label: "Add item" },
                          { value: "Cheque", label: "Add item" },
                        ]}
                        component={RenderSelect}
                      />
                      <Field
                        name="dueDate"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="dueDate"
                        labelClass="form-label"
                        label="Enter Category Name"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="dueDate"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="dueDate"
                        labelClass="form-label"
                        label="Amount Reciver Name"
                        inputClass="form-control"
                        component={RenderField}
                      />

                      <Field
                        name="dueDate"
                        type="date"
                        wrapperClass="col-md-4 mb-3"
                        id="dueDate"
                        labelClass="form-label"
                        label="Due Date"
                        inputClass="form-control"
                        component={RenderField}
                      />
                    </div>
                  </div>
                </div>

                <div className="card h-auto">
                  <div className="card-header">
                    <h3>Add Expense Item</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Field
                        name="shippingAddress"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingAddress"
                        labelClass="form-label"
                        label="Item Name"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingAddress"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingAddress"
                        labelClass="form-label"
                        label="Quantity"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingAddress"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingAddress"
                        labelClass="form-label"
                        label="Rate"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingAddress"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingAddress"
                        labelClass="form-label"
                        label="Amount"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingAddress"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingAddress"
                        labelClass="form-label"
                        label="Total Amount"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingAddress"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingAddress"
                        labelClass="form-label"
                        label="Remark"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="logoimage"
                        type="file"
                        wrapperClass="col-md-4 mb-3"
                        id="logoimage"
                        labelClass="form-label"
                        label="Upload Photo"
                        inputClass="form-control"
                        placeholder="Enter logoimage here..."
                        component={RenderField}
                        onInputChange={{}}
                      />
                      <div>
                        <button type="Submit" className="btn btn-primary me-3">
                          Submit
                        </button>
                        <button type="button" className="btn btn-primary">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};
export default Expense;

import { Field, Form } from "react-final-form";
import RenderField from "../../components/renderField/RenderField";
import RenderSelect from "../../components/renderField/RenderSelect";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import createValidator from "../../components/Forms/FormValidation/createValidator";
import { joiProductDetailsSchema } from "../../components/Forms/FormValidation/joiValidationSchema";
import RenderTextarea from "../../components/renderField/RenderTextarea";
import { create } from "../../../services/api";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { readCategory } from "../../../store/store";
import {
  setCategory,
  setSubCategoryData,
} from "../../../store/slices/categorySlice";
import { productOptions } from "../../../other/options";
import { toast } from "react-toastify";

const ProductDetails = ({
  categories,
  dsetCategory,
  dsetSubCategory,
  dreadCatergory,
  subCategories,
  product,
  updateForm,
}) => {
  const [init, setInit] = useState({
    CustomFields: [{}],
  });
  const [ProductImage, setProductImage] = useState(null);

  useEffect(() => {
    dreadCatergory();
  }, []);

  useEffect(() => {
    const _product = { ...product };
    console.log("as", _product.producttype);
    _product.producttype = productOptions.filter(
      (item) => item.value === _product.producttype
    )?.[0];
    console.log("gh", _product.producttype);

    // setProductImage(_product.productimage);
    if ("productimage" in _product) {
      delete _product.productimage;
    }
    setInit({ ...init, ..._product });
  }, [product]);

  const onSubmit = async (values) => {
    console.log(values);

    const formData = new FormData();
    for (const key in values) {
      if (key === "producttype") {
        formData.append(key, values[key]?.value);
      } else {
        formData.append(key, values[key] || "");
      }
    }
    formData.append("ProductImage", ProductImage);
    try {
      console.log("FORM DATA", formData);

      const data = await create(
        "inventorypost/create_update_product",
        formData
      );
      toast.success("Product saved");
      console.log(data);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const handelProdectImage = (e) => {
    setProductImage(e.target.files[0]);
  };

  const onCategoryChange = (e) => {
    const _categories = e
      ? categories.filter((item) => +item.sno === +e.value)?.[0]?.subCategories
      : [];
    console.log("catagery", _categories);

    if (_categories) {
      const _to = setTimeout(() => {
        dsetSubCategory(_categories);
        clearTimeout(_to);
      }, 500);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Form
            initialValues={init}
            validate={createValidator(joiProductDetailsSchema)}
            mutators={{
              ...arrayMutators,
              changeFieldValue: ([fieldName, fieldValue], state, utils) => {
                utils.changeValue(state, fieldName, () => fieldValue);
              },
            }}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              form: {
                mutators: { push, changeFieldValue },
              },
              submitting,
              values,
              pristine,
            }) => (
              <>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="card h-auto">
                    <div className="card-header">
                      {updateForm ? (
                        <h3>Edit Product</h3>
                      ) : (
                        <h3>Product Details</h3>
                      )}
                    </div>
                    <div className="card-body">
                      <div className="row border-bottom mb-3 pb-3">
                        <Field
                          name="producttype"
                          wrapperClass="col-md-4 mb-3"
                          id="producttype"
                          labelClass="form-label"
                          label="Product Type"
                          isSearchable
                          options={productOptions}
                          component={RenderSelect}
                        />
                      </div>
                      <div className="row">
                        <Field
                          name="productname"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="productname"
                          labelClass="form-label"
                          label="Product Name"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="productdetails"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="productdetails"
                          labelClass="form-label"
                          label="Product Details"
                          inputClass="form-control"
                          rows={5}
                          component={RenderTextarea}
                        />
                        <Field
                          name="tax"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="tax"
                          labelClass="form-label"
                          label="Tax %"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="productcode"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="productcode"
                          labelClass="form-label"
                          label="Product Code"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="unitid"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="unitid"
                          labelClass="form-label"
                          label="Unit"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="saleprice"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="saleprice"
                          labelClass="form-label"
                          label="Sale Price"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="pricewithouttax"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="pricewithouttax"
                          labelClass="form-label"
                          label="Price With OutTax"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="purchaseprice"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="purchaseprice"
                          labelClass="form-label"
                          label="Purchase Price"
                          inputClass="form-control"
                          component={RenderField}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="card-header">
                        </div> */}
                  <div className="card h-auto">
                    <div className="card-header">
                      <h3>Other Details</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <Field
                          name="categoryname"
                          wrapperClass="col-md-4 mb-3"
                          id="categoryname"
                          labelClass="form-label"
                          label="Category Name"
                          options={categories.map((item) => ({
                            value: item.sno,
                            label: item.categoryname,
                          }))}
                          component={RenderSelect}
                          onSelectChange={(e) => {
                            changeFieldValue("SubCategoryName", null);
                            onCategoryChange(e);
                          }}
                        />
                        <Field
                          name="subcategoryname"
                          wrapperClass="col-md-4 mb-3"
                          id="subcategoryname"
                          labelClass="form-label"
                          label="Sub Category Name"
                          options={subCategories.map((item) => ({
                            value: item.sno,
                            label: item.categoryname,
                          }))}
                          component={RenderSelect}
                        />

                        <Field
                          name="stockavailable"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="stockavailable"
                          labelClass="form-label"
                          label="Stock Available"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="reorderlevel"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="reorderlevel"
                          labelClass="form-label"
                          label="Reorderlevel"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="remark"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="remark"
                          labelClass="form-label"
                          label="Remark"
                          inputClass="form-control"
                          component={RenderField}
                        />
                      </div>
                      <div className="row">
                        <FieldArray name="CustomFields">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div
                                key={`ProductDetails-CustomFields-${index.toString()}`}
                                className="mb-3 row"
                              >
                                <Field
                                  name={`${name}.detailName`}
                                  type="text"
                                  wrapperClass="col-md-5 mb-3"
                                  id={`${name}.detailName`}
                                  labelClass="form-label"
                                  label={`${name}.detailName`}
                                  inputClass="form-control"
                                  //   placeholder="Enter otherDetails.detailname here..."
                                  component={RenderField}
                                />
                                <Field
                                  name={`${name}.detailValue`}
                                  type="text"
                                  wrapperClass="col-md-5 mb-3"
                                  id={`${name}.detailValue`}
                                  labelClass="form-label"
                                  label={`${name}.detailValue`}
                                  inputClass="form-control"
                                  //   placeholder="Enter otherDetails.detailvalue here..."
                                  component={RenderField}
                                />
                                <div className="col-md-2 d-flex align-items-center">
                                  <button
                                    onClick={() => fields.remove(index)}
                                    type="button"
                                    disabled={fields.length < 2}
                                    className={`flex-fill btn btn-success text-white text-decoration-none`}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            ))
                          }
                        </FieldArray>
                        {values.CustomFields &&
                          values.CustomFields.length < 5 && (
                            <div className="text-end mb-3">
                              <button
                                onClick={() => push("CustomFields", undefined)}
                                type="button"
                                className={`flex-fill btn btn-link text-success text-decoration-none`}
                              >
                                Add other custom fields
                              </button>
                            </div>
                          )}
                        <Field
                          name="productimage"
                          type="file"
                          wrapperClass="col-md-4 mb-3"
                          id="productimage"
                          labelClass="form-label"
                          label="Product Image"
                          inputClass="form-control"
                          placeholder="Product Image"
                          component={RenderField}
                          onInputChange={handelProdectImage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <button
                      type="sumbit"
                      disabled={submitting || pristine}
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </form>
                <pre>{JSON.stringify(values, null, 4)}</pre>
              </>
            )}
          />
        </div>
      </div>
    </>
  );
};
const mapState = (state) => ({
  categories: state.categories.data,
  subCategories: state.categories.subCatData,
  product: state.products.datum,
});

const mapDispatch = (dispatch) => ({
  dreadCatergory: (p) => dispatch(readCategory(p)),
  dsetCategory: (p) => dispatch(setCategory(p)),
  dsetSubCategory: (p) => dispatch(setSubCategoryData(p)),
});

export default connect(mapState, mapDispatch)(ProductDetails);

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import CreateCategory from "./CreateCategory";
import { Card } from "react-bootstrap";
import { readCategory } from "../../../store/store";
import ModalWrapper from "../ModalWrapper";
import {
  setCategory,
  setSubCategory,
} from "../../../store/slices/categorySlice";
import CreateSubCategory from "./CreateSubCategory";
import { read as destroy } from "../../../services/api";
import { toast } from "react-toastify";

const ManageCategory = ({
  categories,
  dreadCategory,
  dsetCategory,
  dsetSubCategory,
}) => {
  const targetComponent = [CreateCategory, CreateSubCategory];

  const [targetIndex, setTargetIndex] = useState(0);
  const [updateForm, setUpdateForm] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    dreadCategory();
  }, []);

  const editCategory = (cat) => {
    // CreateCategory
    setTargetIndex(0);
    setUpdateForm(true);
    dsetCategory(cat);
    setShowModal(true);
  };

  const addSubCategory = (cat) => {
    // CreateSubCategory
    setTargetIndex(1);
    dsetSubCategory({});
    setUpdateForm(false);
    dsetCategory(cat);
    setShowModal(true);
  };

  const editSubCategory = (cat) => {
    // CreateSubCategory
    setTargetIndex(1);
    setUpdateForm(true);
    dsetSubCategory(cat);
    setShowModal(true);
  };

  const deleteSubCategory = async (subCat) => {
    if (window.confirm("Are you sure want to delete the sub-category??")) {
      try {
        await destroy("InventoryPost/delete_cat_subcat", { catId: subCat.sno });
        dreadCategory();
        toast.success("Record has been deleted successfully...!!!");
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <h3 className="mb-3">Manage Category and Sub Category</h3>

      <Card>
        <Card.Header>
          <Card.Title>Add Category</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="col-12 col-sm-6">
            <CreateCategory />
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          <Card.Title>Categories</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Category name</th>
                  <th>Desc</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!!categories.length &&
                  categories.map((item) => (
                    <>
                      <tr>
                        <td>{item.categoryname}</td>
                        <td>{item.description}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => editCategory(item)}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary mr-2"
                            onClick={() => addSubCategory(item)}
                          >
                            Add sub category
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td colSpan={2}>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Sub category name</th>
                                <th>Desc</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!!item.subCategories.length &&
                                item.subCategories.map((_item) => (
                                  <tr>
                                    <td>{_item.categoryname}</td>
                                    <td>{_item.description}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-primary mr-2"
                                        onClick={() => editSubCategory(_item)}
                                      >
                                        Add sub category
                                      </button>{" "}
                                      <button
                                        type="button"
                                        className="btn btn-primary mr-2"
                                        onClick={() => deleteSubCategory(_item)}
                                      >
                                        Delete
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              {!item.subCategories.length && (
                                <tr>
                                  <td colSpan={3} className="text-center">
                                    No records found...
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  ))}
                {!categories.length && (
                  <tr>
                    <td colSpan={2}>No records found...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>

      <ModalWrapper
        show={showModal}
        handleClose={handleClose}
        component={targetComponent[targetIndex]}
        updateForm={updateForm}
      />
    </>
  );
};

const mapState = (state) => ({
  categories: state.categories.data,
});

const mapDispatch = (dispatch) => ({
  dreadCategory: (p) => dispatch(readCategory(p)),
  dsetCategory: (p) => dispatch(setCategory(p)),
  dsetSubCategory: (p) => dispatch(setSubCategory(p)),
});

export default connect(mapState, mapDispatch)(ManageCategory);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { read } from "../../services/api";

const readParty = createAsyncThunk("read/party", async (payload) => {
  const resp = await read("PartyGet/branch_parties_display", payload);
  return resp.data.value.managePartyData;
});

const readPartyById = createAsyncThunk("read/partyById", async (payload) => {
  const resp = await read("PartyGet/get_branch_parties", payload);
  return resp.data.value;
});
export { readParty, readPartyById };

import { createAsyncThunk } from "@reduxjs/toolkit";
import { read } from "../../services/api";

const readCompanyPartyVat = createAsyncThunk(
  "read/companyPartyVat",
  async (payload) => {
    const resp = await read("PartyGet/get_all_customerpartyname", payload);
    return resp.data.value;
  }
);

const readListSaleIvoice = createAsyncThunk(
  "read/listSaleInvoice",
  async (payload) => {
    const resp = await read("SalesGet/get_selling_invoices", payload);
    return resp.data.value;
  }
);
const readListSaleIvoiceById = createAsyncThunk(
  "read/listSaleInvoiceById",
  async (payload) => {
    const resp = await read("SalesGet/get_sales_invoice", payload);
    return resp.data.value;
  }
);
const readViewSaleInvoice = createAsyncThunk(
  "read/view_sale_invoice",
  async (payload) => {
    const resp = await read("SalesGet/get_sales_preview_invoice", payload);
    return resp.data.value;
  }
);
export {
  readCompanyPartyVat,
  readListSaleIvoice,
  readListSaleIvoiceById,
  readViewSaleInvoice,
};

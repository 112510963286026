import { Table } from "react-bootstrap";

const StokSummary = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h3>Stock Summary</h3>
            </div>
            <div className="body">
              <div className="row col-md-4 m-3">
                <input type="date" onChange={{}} className="form-control" />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="body">
              <div className="row">
                <div className="col">ProdectName</div>
                <div className="col">Image</div>
                <div className="col">Categary</div>
                <div className="col">Available Stok</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="body">
              <div className="row">
                <Table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>New Update</th>
                      <th>Previous Stock</th>
                      <th>Total Stock</th>
                      <th>Sale Price</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Date</td>
                      <td>New Update</td>
                      <td>Previous Stock</td>
                      <td>Total Stock</td>
                      <td>Sale Price</td>
                      <td>Remark</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StokSummary;

import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import RenderField from "../components/renderField/RenderField";
import RenderTextarea from "../components/renderField/RenderTextarea";
import RenderSelect from "../components/renderField/RenderSelect";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { create } from "../../services/api";
import createValidator from "../components/Forms/FormValidation/createValidator";
import { joiCompanySetupSchema } from "../components/Forms/FormValidation/joiValidationSchema";
import { readCompany } from "../../store/store";
import { readCountries } from "../../store/thunks/readCountry";

const CompanySetup = ({
  company,
  dreadCompany,
  isCompanySetup,
  countries,
  dreadCountries,
}) => {
  const [initVals, setInitVals] = useState({
    bankDetails: [{}],
    otherDetails: [{}],
    iscorpratetax: "No",
  });

  const [signImage, setSignImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);

  useEffect(() => {
    dreadCompany();
    dreadCountries();
  }, []);

  useEffect(() => {
    let { bankDetails, otherDetails, countryname } = company;
    bankDetails = bankDetails?.length ? bankDetails : [{}];
    otherDetails = otherDetails?.length ? otherDetails : [{}];
    countryname = countries.filter(
      (item) => +item.countryCode === +countryname
    );
    if (countryname.length) {
      countryname = {
        value: countryname.countryCode,
        label: countryname.countryname,
      };
    } else {
      countryname = null;
    }

    setInitVals((current) => ({
      ...current,
      ...company,
      bankDetails,
      otherDetails,
      countryname,
    }));
  }, [company]);

  const handleSignatureFileChange = (e) => {
    setSignImage(e.target.files[0]);
  };

  const handleLogoFileChange = (e) => {
    setLogoImage(e.target.files[0]);
  };

  const onSubmit = async (values) => {
    console.log(signImage, logoImage, values);
    const formData = new FormData();
    console.log("fome", formData);

    for (const key in values) {
      console.log(key, values[key]);
      if (key === "countryname") {
        formData.append(key, values[key]?.value);
      } else {
        formData.append(key, values[key] || "");
      }
    }

    formData.append("signatureimage", signImage);
    formData.append("logoimage", logoImage);

    try {
      const res = await create("CompanyPost/company_create_update", formData);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h3>Company Setup</h3>
            </div>
            <div className="card-body">
              <Form
                initialValues={initVals}
                validate={createValidator(joiCompanySetupSchema)}
                mutators={{ ...arrayMutators }}
                onSubmit={onSubmit}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  submitting,
                  values,
                  pristine,
                }) => (
                  <>
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="row">
                        {/* <Field
                          name="cid"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="cid"
                          labelClass="form-label"
                          label="Company ID"
                          inputClass="form-control"
                          placeholder="Enter company id here..."
                          component={RenderField}
                        /> */}

                        <Field
                          name="companyname"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="companyname"
                          labelClass="form-label"
                          label="Company Name"
                          inputClass="form-control"
                          placeholder="Enter company name here..."
                          component={RenderField}
                        />

                        {/* <Field
                          name="countrycode"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="countrycode"
                          labelClass="form-label"
                          label="Country Code"
                          inputClass="form-control"
                          placeholder="Enter country code here..."
                          component={RenderField}
                        /> */}

                        <Field
                          name="phone"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="phone"
                          labelClass="form-label"
                          label="Phone"
                          inputClass="form-control"
                          placeholder="Enter phone here..."
                          component={RenderField}
                        />

                        <Field
                          name="mobile"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="mobile"
                          labelClass="form-label"
                          label="Mobile"
                          inputClass="form-control"
                          placeholder="Enter mobile here..."
                          component={RenderField}
                        />
                        <Field
                          name="fax"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="fax"
                          labelClass="form-label"
                          label="Fax"
                          inputClass="form-control"
                          placeholder="Enter fax here..."
                          component={RenderField}
                        />
                        <Field
                          name="countryname"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="countryname"
                          labelClass="form-label"
                          label="Country Name"
                          // inputClass="form-control"
                          isSearchable
                          options={countries.map((item) => ({
                            value: item.countryCode,
                            label: item.countryname,
                          }))}
                          component={RenderSelect}
                        />
                        <Field
                          name="state"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="state"
                          labelClass="form-label"
                          label="State"
                          inputClass="form-control"
                          placeholder="Enter state here..."
                          component={RenderField}
                        />

                        <Field
                          name="city"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="city"
                          labelClass="form-label"
                          label="City"
                          inputClass="form-control"
                          placeholder="Enter city here..."
                          component={RenderField}
                        />
                        <Field
                          name="address"
                          wrapperClass="col-md-4 mb-3"
                          id="address"
                          labelClass="form-label"
                          label="Address"
                          inputClass="form-control"
                          rows={4}
                          placeholder="Enter address here..."
                          component={RenderTextarea}
                        />
                        <Field
                          name="pincode"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="pincode"
                          labelClass="form-label"
                          label="Pincode"
                          inputClass="form-control"
                          placeholder="Enter pincode here..."
                          component={RenderField}
                        />
                        <Field
                          name="vatid"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="vatid"
                          labelClass="form-label"
                          label="VAT ID"
                          inputClass="form-control"
                          placeholder="Enter vatid here..."
                          component={RenderField}
                          disabled={isCompanySetup}
                        />
                        {/* <Field
                          name="signatureimage"
                          type="file"
                          wrapperClass="col-md-4 mb-3"
                          id="signatureimage"
                          labelClass="form-label"
                          label="Signature"
                          inputClass="form-control"
                          placeholder="Enter signatureimage here..."
                          component={RenderField}
                          onInputChange={handleSignatureFileChange}
                        /> */}
                        <Field
                          name="logoimage"
                          type="file"
                          wrapperClass="col-md-4 mb-3"
                          id="logoimage"
                          labelClass="form-label"
                          label="Logo"
                          inputClass="form-control"
                          placeholder="Enter logoimage here..."
                          component={RenderField}
                          onInputChange={handleLogoFileChange}
                        />
                        <Field
                          name="email"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="email"
                          labelClass="form-label"
                          label="Email"
                          inputClass="form-control"
                          placeholder="Enter email here..."
                          component={RenderField}
                        />
                        <Field
                          name="website"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="website"
                          labelClass="form-label"
                          label="Website"
                          inputClass="form-control"
                          placeholder="Enter website here..."
                          component={RenderField}
                        />
                        <Field
                          name="contactpersonname"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="contactpersonname"
                          labelClass="form-label"
                          label="Contact Person Name"
                          inputClass="form-control"
                          placeholder="Enter contactpersonname here..."
                          component={RenderField}
                        />
                        <Field
                          name="username"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="username"
                          labelClass="form-label"
                          label="Username"
                          inputClass="form-control"
                          placeholder="Enter username here..."
                          component={RenderField}
                          disabled={isCompanySetup}
                        />
                        <Field
                          name="termandcondition"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="termandcondition"
                          labelClass="form-label"
                          label="Terms and Conditions"
                          inputClass="form-control"
                          placeholder="Enter termandcondition here..."
                          component={RenderTextarea}
                          rows={4}
                        />
                        {/* <Field
                          name="qrcode"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="qrcode"
                          labelClass="form-label"
                          label="QR Code"
                          inputClass="form-control"
                          placeholder="Enter qrcode here..."
                          component={RenderField}
                        /> */}
                        <Field
                          name="startinvoiceno"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="startinvoiceno"
                          labelClass="form-label"
                          label="Start Invoice Number"
                          inputClass="form-control"
                          placeholder="Enter startinvoiceno here..."
                          component={RenderField}
                        />
                        <Field
                          name="licenseno"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="licenseno"
                          labelClass="form-label"
                          label="License Number"
                          inputClass="form-control"
                          placeholder="Enter licenseno here..."
                          component={RenderField}
                        />
                        <Field
                          name="issuedate"
                          type="date"
                          wrapperClass="col-md-4 mb-3"
                          id="issuedate"
                          labelClass="form-label"
                          label="Issue Date"
                          inputClass="form-control"
                          placeholder="Enter issuedate here..."
                          component={RenderField}
                        />
                        <Field
                          name="expirydate"
                          type="date"
                          wrapperClass="col-md-4 mb-3"
                          id="expirydate"
                          labelClass="form-label"
                          label="Expiry Date"
                          inputClass="form-control"
                          placeholder="Enter expirydate here..."
                          component={RenderField}
                        />
                        <div className="col-md-4 mb-3">
                          <label htmlFor="">Is Corporate Tax?</label>
                          {/* <Field
                            name="iscorpratetax"
                            type="radio"
                            wrapperClass="form-check"
                            id="iscorpratetax_yes"
                            labelClass="form-check-label"
                            label="Yes"
                            value={true}
                            inputClass="form-check-input"
                            component={RenderField}
                          /> */}
                          <div className="form-check mb-2">
                            <Field
                              className="form-check-input"
                              name="iscorporatetax"
                              id="iscorporatetax_yes"
                              component="input"
                              type="radio"
                              value="Yes"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="iscorporatetax_yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check mb-2">
                            <Field
                              className="form-check-input"
                              name="iscorporatetax"
                              id="iscorporatetax_no"
                              component="input"
                              type="radio"
                              value="No"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="iscorporatetax_no"
                            >
                              No
                            </label>
                          </div>
                          {/* <Field
                            name="iscorpratetax"
                            type="radio"
                            wrapperClass="form-check"
                            id="iscorpratetax_no"
                            labelClass="form-check-label"
                            label="No"
                            value={false}
                            inputClass="form-check-input"
                            component={RenderField}
                          /> */}
                        </div>
                        <Field
                          name="coparatetax"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="coparatetax"
                          labelClass="form-label"
                          label="Corporate Tax"
                          inputClass="form-control"
                          placeholder="Enter coparatetax here..."
                          component={RenderField}
                        />
                        <Field
                          name="effectiveregistrationdate"
                          type="date"
                          wrapperClass="col-md-4 mb-3"
                          id="effectiveregistrationdate"
                          labelClass="form-label"
                          label="Effective Registration Date"
                          inputClass="form-control"
                          placeholder="Enter effectiveregistrationdate here..."
                          component={RenderField}
                        />
                        <Field
                          name="currenttaxperiodmonthandyear"
                          type="month"
                          wrapperClass="col-md-4 mb-3"
                          id="currenttaxperiodmonthandyear"
                          labelClass="form-label"
                          label="Current Tax Period (month-year)"
                          inputClass="form-control"
                          placeholder="Enter currenttaxperiodmonthandyear here..."
                          component={RenderField}
                        />
                        {/* <Field
                          name="createdormodifiedbyuserid"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="createdormodifiedbyuserid"
                          labelClass="form-label"
                          label="createdormodifiedbyuserid"
                          inputClass="form-control"
                          placeholder="Enter createdormodifiedbyuserid here..."
                          component={RenderField}
                        /> */}

                        <FieldArray name="bankDetails">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div
                                key={`CompanySetup-M0-${index.toString()}`}
                                className="mb-3 row"
                              >
                                <Field
                                  name={`${name}.accountnumber`}
                                  type="text"
                                  wrapperClass="col-md-3 mb-3"
                                  id={`${name}.accountnumber`}
                                  labelClass="form-label"
                                  label={`${name}.accountnumber`}
                                  inputClass="form-control"
                                  // placeholder="Enter bankDetails.accountnumber here..."
                                  component={RenderField}
                                />
                                <Field
                                  name={`${name}.bankname`}
                                  type="text"
                                  wrapperClass="col-md-3 mb-3"
                                  id={`${name}.bankname`}
                                  labelClass="form-label"
                                  label={`${name}.bankname`}
                                  inputClass="form-control"
                                  // placeholder="Enter bankDetails.bankname here..."
                                  component={RenderField}
                                />
                                <Field
                                  name={`${name}.branchname`}
                                  type="text"
                                  wrapperClass="col-md-3 mb-3"
                                  id={`${name}.branchname`}
                                  labelClass="form-label"
                                  label={`${name}.branchname`}
                                  inputClass="form-control"
                                  // placeholder="Enter bankDetails.branchname here..."
                                  component={RenderField}
                                />
                                <Field
                                  name={`${name}.bankcode`}
                                  type="text"
                                  wrapperClass="col-md-3 mb-3"
                                  id={`${name}.bankcode`}
                                  labelClass="form-label"
                                  label={`${name}.bankcode`}
                                  inputClass="form-control"
                                  // placeholder="Enter bankDetails.bankcode here..."
                                  component={RenderField}
                                />

                                {fields.length > 1 && (
                                  <div className="text-end">
                                    <button
                                      onClick={() => fields.remove(index)}
                                      type="button"
                                      className={`flex-fill btn btn-link text-success text-decoration-none`}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))
                          }
                        </FieldArray>

                        {values.bankDetails &&
                          values.bankDetails.length < 5 && (
                            <div className="text-end mb-3">
                              <button
                                onClick={() => push("bankDetails", undefined)}
                                type="button"
                                className={`flex-fill btn btn-link text-success text-decoration-none`}
                              >
                                Add bank details
                              </button>
                            </div>
                          )}

                        <FieldArray name="otherDetails">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div
                                key={`CompanySetup-M1-${index.toString()}`}
                                className="mb-3 row"
                              >
                                <Field
                                  name={`${name}.detailname`}
                                  type="text"
                                  wrapperClass="col-md-6 mb-3"
                                  id={`${name}.detailname`}
                                  labelClass="form-label"
                                  label={`${name}.detailname`}
                                  inputClass="form-control"
                                  //   placeholder="Enter otherDetails.detailname here..."
                                  component={RenderField}
                                />
                                <Field
                                  name={`${name}.detailvalue`}
                                  type="text"
                                  wrapperClass="col-md-6 mb-3"
                                  id={`${name}.detailvalue`}
                                  labelClass="form-label"
                                  label={`${name}.detailvalue`}
                                  inputClass="form-control"
                                  //   placeholder="Enter otherDetails.detailvalue here..."
                                  component={RenderField}
                                />

                                {fields.length > 1 && (
                                  <div className="text-end">
                                    <button
                                      onClick={() => fields.remove(index)}
                                      type="button"
                                      className={`flex-fill btn btn-link text-success text-decoration-none`}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))
                          }
                        </FieldArray>
                        {values.otherDetails &&
                          values.otherDetails.length < 5 && (
                            <div className="text-end mb-3">
                              <button
                                onClick={() => push("otherDetails", undefined)}
                                type="button"
                                className={`flex-fill btn btn-link text-success text-decoration-none`}
                              >
                                Add other details
                              </button>
                            </div>
                          )}
                        <div className="d-flex">
                          <button
                            type="sumbit"
                            disabled={submitting || pristine}
                            className="btn btn-primary"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                    <pre>{JSON.stringify(values, null, 4)}</pre>
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  company: state.company.datum,
  isCompanySetup: state.auth?.auth?.value?.iscompanysetup,
  countries: state.countries.data,
});
const mapDispatch = (dispatch) => ({
  dreadCompany: (p) => dispatch(readCompany(p)),
  dreadCountries: (p) => dispatch(readCountries(p)),
});

export default connect(mapState, mapDispatch)(CompanySetup);

import { Dropdown, Table } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { connect } from "react-redux";
import { readListSaleIvoice } from "../../../store/store";
import { useEffect, useState } from "react";
import PageUI from "./PageUI";
import { useNavigate } from "react-router-dom";

const SaleInvoiceList = ({ dreadSaleInvoiceList, SaleInvoiceList }) => {
  const navigate = useNavigate();
  // debugger;
  console.log(SaleInvoiceList);
  const [monthYear, setMonthYear] = useState("2024-08");

  useEffect(() => {
    dreadSaleInvoiceList({ YearandMonth: monthYear });
  }, [monthYear]);

  const getSaleInvoiceDate = (e) => {
    console.log(e.target.value, "==========");
    setMonthYear(e.target.value);
  };

  const editSaleInvoice = (item) => {
    const { invoiceId } = item;
    navigate(`/editSaleInvoice/${invoiceId}`);
  };

  const preViewSaleInvoice = (item) => {
    const { invoiceId } = item;
    console.log("invoiceid", invoiceId);
    navigate(`/viewSaleInvoice/${invoiceId}`);
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3>Sale Invoice List</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4 mb-3">
              <label className="form-label">
                <strong>Month & Year</strong>
              </label>
              <div>
                <input
                  type="month"
                  className="form-control"
                  onChange={getSaleInvoiceDate}
                  value={monthYear}
                />
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">
                <strong>Select Data Range</strong>
              </label>
              <DateRangePicker
                initialSettings={{
                  startDate: "10/5/2022",
                  endDate: "3/6/2022",
                }}
              >
                <input
                  type="text"
                  className="form-control input-daterange-timepicker"
                />
              </DateRangePicker>
            </div>
          </div>
          <Table responsive className="w-100">
            <thead>
              <tr>
                <th>Invoic No</th>
                <th>Party Name</th>
                <th>Company Name</th>
                <th>Iovoic Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!!SaleInvoiceList.length &&
                SaleInvoiceList.map((item) => (
                  <tr>
                    <td>{item.invoiceNo}</td>
                    <td>{item.partyName}</td>
                    <td>{item.companyName}</td>
                    <td>{item.invoiceStatus}</td>
                    <td>
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-primary me-3"
                          onClick={() => preViewSaleInvoice(item)}
                        >
                          View
                        </button>
                        <Dropdown>
                          <Dropdown.Toggle variant="primary">
                            Dropdown button
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              as="button"
                              onClick={() => editSaleInvoice(item)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item as="button" onClick={{}}>
                              Cancel
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              {!SaleInvoiceList.length && (
                <tr>
                  <td>
                    <td>No records found...</td>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* <PageUI size={1} /> */}
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  SaleInvoiceList: state.sales.listSaleIvoice.manageSaleInvoiceData || [],
});

const mapDispatch = (dispatch) => ({
  dreadSaleInvoiceList: (q) => dispatch(readListSaleIvoice(q)),
});

export default connect(mapState, mapDispatch)(SaleInvoiceList);

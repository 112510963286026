const RenderTextarea = ({
  input,
  wrapperClass,
  labelClass,
  id,
  label,
  inputClass,
  placeholder,
  onInputChange,
  rows,
  meta: { touched, error },
}) => {
  return (
    <>
      <div className={wrapperClass}>
        <label className={labelClass} htmlFor={id}>
          <strong>{label}</strong>
        </label>
        <textarea
          {...input}
          className={inputClass}
          id={id}
          placeholder={placeholder}
          rows={rows || 5}
        />
        {touched && error && <span className="text-danger">{error}</span>}
      </div>
    </>
  );
};

export default RenderTextarea;

import { createSlice } from "@reduxjs/toolkit";
import { readCountries } from "../thunks/readCountry";

const countrySlice = createSlice({
  name: "countries",
  initialState: {
    data: [],
    datum: {},
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(readCountries.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readCountries.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(readCountries.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default countrySlice.reducer;

const ProfitLossReport = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h3>Profit/Loss</h3>
            </div>
            <div className="card-body">
              <div className="row">company Name Address</div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Particulars</h3>
            </div>
            <div className="card-body">
              <div className="row">company Name Address</div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Net Profit</h3>
            </div>
            <div className="card-body">
              <div className="row">company Name Address</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfitLossReport;

import { Field, Form } from "react-final-form";
import RenderSelect from "../../components/renderField/RenderSelect";
import RenderField from "../../components/renderField/RenderField";
import RenderTextarea from "../../components/renderField/RenderTextarea";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { readPartyById, readCompanyPartyVat } from "../../../store/store";
import RenderAsyncSelect from "../../components/renderField/RenderAsyncSelect";
import ModalWrapper from "../ModalWrapper";
import AddItem from "./AddItem";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { create } from "../../../services/api";
import { salesInvoiceOption } from "../../../other/options";
import { toast } from "react-toastify";

const CreateSale = ({
  dreadCompanyPartyVat,
  companyPartyVatData,
  drparty,
  party,
  saleInvoiceEdit,
  products,
  updateForm,
}) => {
  console.log(saleInvoiceEdit, "===================");
  const [showAddItem, setShowAddItem] = useState(false);
  const showAddItemModal = () => setShowAddItem(true);
  const closeAddItemModal = () => setShowAddItem(false);

  const [init, setInit] = useState({
    csId: 0,
    partyId: 0,
    invoiceNo: null,
    invoiceDate: null,
    taxableAmount: 0,
    netAmount: 0,
    vatId: null,
    billingCity: null,
    billingState: null,
    billingAddress: null,
    customerSellingInvoiceDetail: [
      // {
      //   sno: 0,
      //   productid: 5,
      //   productname: "jalebi new",
      //   amountperunit: 100,
      //   qty: 4,
      //   Tax: 5,
      //   totalamount: 420,
      //   discount: 0,
      //   disountAmount: 0,
      //   productcode: 0,
      //   remark: "no remark product is great",
      //   unitname: "gram",
      //   taxamount: 20,
      //   priceafterdiscount: 420,
      //   pricewithtax: 420,
      //   pricewithouttax: 400,
      //   discountPercentageorrupees: "", // percentage ,aed
      //   producttype: 0, // Item = 1, Service = 2
      //   servicestartdate: "2024-08-05T10:15:11.554Z",
      //   serviceenddate: "2024-08-05T10:15:11.554Z",
      // },
    ],
    salesProductRemoveList: [
      {
        prodid: 0,
      },
    ],
    customeFields: [
      {
        detailName: "package",
        detailValue: "23",
      },
    ],
    transactionId: 0,
    transactionDate: null,
    // "transactionType": "",
    transactionRemark: "",
    invoiceType: "sale",
    paymentMode: "cash",
    isFullyPaid: false,
    paidAmount: 50,
    dueDate: "2024-09-07T07:57:39.955Z",
    grno: "",
    shippingAddress: "street  village city",
    shippingPinCode: "340567",
    shippingContactPerson: "mr . shipping person",
    shippingContactMobile: "9876548910",
    shippingCity: "meerut",
    shippingState: "goa",
    partyName: "mr party",
    companyName: "party company",
    billingContactno: "9876543210",
    isNewCustomer: true,
    //   "tax": 6,
    //   "taxAmount":50,
    qid: 0,
    billingCountryName: "india",
    //   "isExclusive": true,
    billingPinCode: "870654",
    shippingVatid: "90785634120",
    remark: "no remark",
    salesInvoiceType: "Tax Invoice", // Tax Invoice , Cash Invoice, Bill of Supply
    isBillTypeCash: false,
    isEditPaidAmount: false,
    editAmount: 0,
    currency: 47, // required  // get Id from getcountry name API
    shippingCountry: "India",
    currencyRate: 1.0,
    bankId: null, // bydefault it will be primary bank,
    discount: 0.0,
    discountAmount: 0.0,
    discountPercentageOrAED: null, // "percentage","aed"

    // customerSellingInvoiceDetail: [],
    // salesInvoiceType: { value: "Tax Invoice", label: "Tax Invoice" },
  });
  const [partyId, setPartyId] = useState(0);

  useEffect(() => {
    console.log("edit", saleInvoiceEdit);
    const _saleInvoice = { ...saleInvoiceEdit };

    _saleInvoice.paymentMode = _saleInvoice.transctiondetails?.[0]?.paymentmode;
    _saleInvoice.invoiceDate = _saleInvoice.invoiceDate?.split("T")?.[0];
    _saleInvoice.dueDate = _saleInvoice.dueDate?.split("T")?.[0];
    setPartyId(_saleInvoice.partyId);
    _saleInvoice.companyName = {
      value: _saleInvoice.partyId,
      label: _saleInvoice.companyName,
    };

    _saleInvoice.salesInvoiceType = {
      value: _saleInvoice.salesInvoiceType,
      label: _saleInvoice.salesInvoiceType,
    };
    _saleInvoice.partyName = {
      value: _saleInvoice.partyId,
      label: _saleInvoice.partyName,
    };
    _saleInvoice.vatId = {
      value: _saleInvoice.partyId,
      label: _saleInvoice.vatId,
    };

    // debugger;
    _saleInvoice.customerSellingInvoiceDetail =
      _saleInvoice.customerSellingInvoiceDetail?.map((item) => ({
        ...item,
        productname: item.productName,
        productcode: item.productCode,
        unitname: item.unit,
        amountperunit: item.amountPerUnit,

        pricewithtax: item.priceWithTax,
        pricewithouttax: item.priceWithoutTax,
        Tax: item.tax,
        taxamount: item.taxAmount,
      }));

    setInit(_saleInvoice);
  }, [saleInvoiceEdit]);

  const onsubmit = async (value) => {
    try {
      console.log("value=", value);
      const _value = { ...value };
      if (!_value.isNewCustomer || _value.isNewCustomer === false) {
        _value.companyName = _value.companyName?.label;
        _value.partyName = _value.partyName?.label;

        if (typeof _value.vatId === "object") {
          _value.vatId = _value.vatId.label;
        }

        _value.billingPinCode = "";
        _value.billingCountryName = "";
        _value.billingCity = "";
        _value.billingState = "";
        _value.billingAddress = "";
        _value.billingContactno = "";
        _value.isNewCustomer = false;
      }
      for (let item of _value.customerSellingInvoiceDetail) {
        item.producttype = item.producttype === "product" ? 1 : 2;
        item.servicestartdate = new Date().toISOString();
        const endDate = new Date().setMonth(new Date().getMonth() + 1);
        item.serviceenddate = new Date(endDate).toISOString();
      }
      _value.salesInvoiceType = _value.salesInvoiceType.value;

      _value.customeFields = [
        {
          detailName: "mark",
          detailValue: "34",
        },
      ];
      _value.salesProductRemoveList = [
        {
          prodid: 0,
        },
      ];

      _value.bankId = null;
      _value.discountPercentageOrAED = null;

      _value.transactionDate = new Date().toISOString();

      console.log("submitvalue", _value);
      const resp = await create(
        "salespost/createupdate_customer_sellinginvoice",
        _value
      );
      if (resp.data.status === "successfully") {
        toast.success("successfully Submitted");
      }
      console.log("response", resp);
    } catch (error) {
      if (error.response?.data?.status === "Invoice Number Already Exist") {
        toast.warning("Invoice Number Already Exist");
      }
      console.log(error);
    }
  };

  const dqpChangeHandler = (index, formValues, value) => {
    const _name = value.target.name;
    const _value = +value.target.value;

    // Find the item from product list
    const customerSellingInvoiceDetail =
      formValues.customerSellingInvoiceDetail.map((item, _index) => {
        if (
          index === _index &&
          ((_name === `customerSellingInvoiceDetail[${_index}].qty` &&
            !isNaN(_value)) ||
            (_name ===
              `customerSellingInvoiceDetail[${_index}].amountperunit` &&
              !isNaN(_value)) ||
            (_name === `customerSellingInvoiceDetail[${_index}].discount` &&
              !isNaN(_value) &&
              _value <= 100) ||
            _name ===
              `customerSellingInvoiceDetail[${_index}].discountPercentageorrupees`)
        ) {
          item.qty = Math.abs(
            _name === `customerSellingInvoiceDetail[${index}].qty`
              ? _value
              : item.qty
          );

          item.amountperunit = Math.abs(
            _name === `customerSellingInvoiceDetail[${index}].amountperunit`
              ? _value
              : item.amountperunit
          );

          item.discount = Math.abs(
            _name === `customerSellingInvoiceDetail[${index}].discount`
              ? _value
              : item.discount
          );

          item.discountPercentageorrupees =
            _name ===
            `customerSellingInvoiceDetail[${index}].discountPercentageorrupees`
              ? value.target.value
              : item.discountPercentageorrupees;

          item.totalamount = item.qty * item.amountperunit;

          if (
            item.discountPercentageorrupees === "percentage"
            // && item.discount !== 0
          ) {
            item.disountAmount = (item.totalamount * item.discount) / 100;
            item.priceafterdiscount = item.totalamount - item.disountAmount;
          } else if (
            item.discountPercentageorrupees === "aed"
            // && item.discount !== 0
          ) {
            item.disountAmount = item.discount;
            item.priceafterdiscount =
              (item.amountperunit - item.disountAmount) * item.qty;
          }

          item.taxamount = (+item.priceafterdiscount * item.Tax) / 100;
          item.pricewithouttax = item.priceafterdiscount;
          item.pricewithtax = +item.pricewithouttax + +item.taxamount;
          // do calculation here
        }

        return {
          ...item,
          discount: isNaN(item.discount) ? 0 : item.discount,
          qty: isNaN(item.qty) ? 1 : item.qty,
          amountperunit: isNaN(item.amountperunit) ? 0 : item.amountperunit,
          pricewithouttax: isNaN(item.amountperunit) ? 0 : item.pricewithouttax,
          pricewithtax: isNaN(item.amountperunit) ? 0 : item.pricewithtax,
        };
      });

    // customerSellingInvoiceDetail[index] = item;
    setInit({
      ...formValues,
      // customerSellingInvoiceDetail: [...customerSellingInvoiceDetail],
      customerSellingInvoiceDetail,
    });
  };

  const amountChangeHandler = (index, formValues, value) => {
    const _value = value.target.value;

    const customerSellingInvoiceDetail =
      formValues.customerSellingInvoiceDetail.map((item, _index) => {
        if (_index === index) {
          const purchasePrice = +item.qty * +item.amountperunit;
          if (item.discountPercentageorrupees === "percentage") {
            item.discount = Math.abs(
              ((purchasePrice - +_value) / purchasePrice) * 100
            );
          } else if (item.discountPercentageorrupees === "aed") {
            item.discount = Math.abs(+_value - purchasePrice);
          }
        }
        return {
          ...item,
          pricewithtax: _value,
        };
      });
    setInit({
      ...formValues,
      customerSellingInvoiceDetail,
    });
  };

  function onAddItemHandler(values) {
    const item = {
      sno: 0,
      productid: values.productid,
      productname: values.productname,
      amountperunit: values.saleprice,
      qty: 1,
      Tax: values.tax,
      totalamount: values.saleprice,
      discount: 0,
      disountAmount: 0,
      productcode: values.productcode,
      remark: "",
      unitname: values.unitname,
      taxamount: (values.saleprice * values.tax) / 100,
      priceafterdiscount: values.saleprice,
      pricewithtax: values.saleprice + (values.saleprice * values.tax) / 100,
      pricewithouttax: values.saleprice,
      discountPercentageorrupees: "percentage",
      producttype: values.producttype,
      servicestartdate: "",
      serviceenddate: "",
    };
    setInit({
      ...init,
      customerSellingInvoiceDetail: [
        ...init.customerSellingInvoiceDetail,
        item,
      ],
    });
    closeAddItemModal();
  }

  const onChangeSelect = (e, currentValues) => {
    if (e && !currentValues?.isNewCustomer) {
      const { value: bpid } = e;

      const companyPartyVat = companyPartyVatData.filter(
        (item) => item.partyId === bpid
      );

      if (companyPartyVat.length) {
        const { bid } = companyPartyVat[0];

        setPartyId(bpid);

        const companyName = {
          value: companyPartyVat[0].partyId,
          label: companyPartyVat[0].companyName,
        };
        const partyName = {
          value: companyPartyVat[0].partyId,
          label: companyPartyVat[0].partyName,
        };
        const vatId = {
          value: companyPartyVat[0].partyId,
          label: companyPartyVat[0].vatId,
        };

        drparty({ bpid, bid });

        console.log("party==", party);

        setInit({
          ...init,
          companyName,
          partyName,
          vatId,
          ...party,
        });
      }
    } else {
      setInit({
        isNewCustomer: currentValues.isNewCustomer,
        salesInvoiceType: currentValues.salesInvoiceType,
      });
    }
  };

  const companyOptions = async (inputValue) => {
    let res = companyPartyVatData
      .filter((item) =>
        item.companyName
          .toLocaleLowerCase()
          .includes(inputValue.toLocaleLowerCase())
      )
      .map((item) => ({ value: item.partyId, label: item.companyName }));
    return res;
  };

  const partyOptions = async (inputValue) => {
    let res = companyPartyVatData
      .filter((item) =>
        item.partyName
          .toLocaleLowerCase()
          .includes(inputValue.toLocaleLowerCase())
      )
      .map((item) => ({ value: item.partyId, label: item.partyName }));
    return res;
  };

  const vatIdOptions = async (inputValue) => {
    let res = companyPartyVatData
      .filter((item) =>
        item.vatId.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
      )
      .map((item) => ({ value: item.partyId, label: item.vatId }));
    return res;
  };

  const isNewCustomerChangeHandler = (e, currentValues) => {
    setInit({
      isNewCustomer: e.target.checked,
      salesInvoiceType: currentValues.salesInvoiceType,
    });
  };

  useEffect(() => {
    dreadCompanyPartyVat();
  }, [dreadCompanyPartyVat]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Form
            initialValues={init}
            // validate={{}}
            onSubmit={onsubmit}
            mutators={{
              ...arrayMutators,
              changeFieldValue: ([fieldName, fieldValue], state, utils) => {
                utils.changeValue(state, fieldName, () => fieldValue);
              },
            }}
            render={({
              handleSubmit,
              form: {
                mutators: { push, changeFieldValue },
                submitting,
                pristine,
              },
              values,
            }) => (
              <>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="card h-auto">
                    <div className="card-header">
                      {updateForm ? <h3>Edit Sale</h3> : <h3>Create Sale</h3>}
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-8">
                          <Field
                            name="salesInvoiceType"
                            wrapperClass="col-md-4 mb-3"
                            id="salesInvoiceType"
                            labelClass="form-label"
                            label="Tax Invoice"
                            isSearchable
                            options={salesInvoiceOption}
                            component={RenderSelect}
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <Field
                            name="isNewCustomer"
                            type="checkbox"
                            wrapperClass="form-check"
                            inputClass="form-check-input"
                            labelClass="form-check-label"
                            label="New Customer"
                            id="isNewCustomer"
                            component={RenderField}
                            onInputChange={(e) =>
                              isNewCustomerChangeHandler(e, values)
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        {values.isNewCustomer ? (
                          <div className="col-md-8">
                            <div className="row">
                              <Field
                                name="vatId"
                                type="text"
                                wrapperClass="col mb-3"
                                id="vatId"
                                labelClass="form-label"
                                label="vatId"
                                inputClass="form-control"
                                component={RenderField}
                              />
                              <Field
                                name="companyName"
                                type="text"
                                wrapperClass="col mb-3"
                                id="companyName"
                                labelClass="form-label"
                                label="Company Name"
                                inputClass="form-control"
                                component={RenderField}
                              />
                              <Field
                                name="partyName"
                                type="text"
                                wrapperClass="col mb-3"
                                id="partyName"
                                labelClass="form-label"
                                label="Party Name"
                                inputClass="form-control"
                                component={RenderField}
                              />
                            </div>
                            <div className="row">
                              <Field
                                name="billingCountryName"
                                type="text"
                                wrapperClass="col mb-3"
                                id="billingCountryName"
                                labelClass="form-label"
                                label="Billing Country Name"
                                inputClass="form-control"
                                component={RenderField}
                              />
                              <Field
                                name="billingState"
                                type="text"
                                wrapperClass="col mb-3"
                                id="billingState"
                                labelClass="form-label"
                                label="Billing State"
                                inputClass="form-control"
                                component={RenderField}
                              />
                              <Field
                                name="billingCity"
                                type="text"
                                wrapperClass="col mb-3"
                                id="billingCity"
                                labelClass="form-label"
                                label="Billing City"
                                inputClass="form-control"
                                component={RenderField}
                              />
                            </div>
                            <div className="row">
                              <Field
                                name="billingPinCode"
                                type="text"
                                wrapperClass="col mb-3"
                                id="billingPinCode"
                                labelClass="form-label"
                                label="Billing Pin Code"
                                inputClass="form-control"
                                component={RenderField}
                              />
                              <Field
                                name="billingContactno"
                                type="text"
                                wrapperClass="col mb-3"
                                id="billingContactno"
                                labelClass="form-label"
                                label="Billing Contact No"
                                inputClass="form-control"
                                component={RenderField}
                              />
                              <Field
                                name="billingAddress"
                                type="text"
                                wrapperClass="col mb-3"
                                id="billingAddress"
                                labelClass="form-label"
                                label="Billing Address"
                                rows={5}
                                inputClass="form-control"
                                component={RenderTextarea}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-8">
                            <div className="row">
                              <Field
                                name="companyName"
                                wrapperClass="col mb-3"
                                labelClass="form-label"
                                label="Search by company"
                                options={companyPartyVatData
                                  .slice(0, 10)
                                  .map((item) => ({
                                    value: item.partyId,
                                    label: item.companyName,
                                  }))}
                                onSelectChange={(e) =>
                                  onChangeSelect(e, values)
                                }
                                loadOptions={companyOptions}
                                component={RenderAsyncSelect}
                              />
                              <Field
                                name="partyName"
                                wrapperClass="col mb-3"
                                labelClass="form-label"
                                label="Search by Party"
                                options={companyPartyVatData.map((item) => ({
                                  value: item.partyId,
                                  label: item.partyName,
                                }))}
                                onSelectChange={(e) =>
                                  onChangeSelect(e, values)
                                }
                                loadOptions={partyOptions}
                                component={RenderAsyncSelect}
                              />
                            </div>
                            <div className="row">
                              <Field
                                name="vatId"
                                wrapperClass="col mb-3"
                                labelClass="form-label"
                                label="Search by VatId"
                                options={companyPartyVatData.map((item) => ({
                                  value: item.partyId,
                                  label: item.vatId,
                                }))}
                                onSelectChange={(e) =>
                                  onChangeSelect(e, values)
                                }
                                loadOptions={vatIdOptions}
                                component={RenderAsyncSelect}
                              />
                              <Field
                                name="billingAddress"
                                type="text"
                                wrapperClass="col mb-3"
                                id="billingAddress"
                                labelClass="form-label"
                                label="Billing Address"
                                inputClass="form-control"
                                component={RenderField}
                              />
                            </div>
                          </div>
                        )}

                        <div className="col-md-4">
                          <div className="div card">
                            <div className="card-body border border-2">
                              <div>
                                <Field
                                  name="invoiceNo"
                                  type="text"
                                  wrapperClass="col mb-3"
                                  id="invoiceNo"
                                  labelClass="form-label"
                                  label="Invoice No"
                                  inputClass="form-control"
                                  component={RenderField}
                                />
                                <Field
                                  name="invoiceDate"
                                  type="date"
                                  wrapperClass="col mb-3"
                                  id="invoiceDate"
                                  labelClass="form-label"
                                  label="Invoice Date"
                                  inputClass="form-control"
                                  component={RenderField}
                                />

                                <Field
                                  name="dueDate"
                                  type="date"
                                  wrapperClass="col mb-3"
                                  id="dueDate"
                                  labelClass="form-label"
                                  label="Due Date"
                                  inputClass="form-control"
                                  // placeholder="Enter Expiry Date..."
                                  component={RenderField}
                                />
                                <Field
                                  name="remark"
                                  type="text"
                                  wrapperClass="col mb-3"
                                  id="remark"
                                  labelClass="form-label"
                                  label="Remark"
                                  inputClass="form-control"
                                  rows={5}
                                  component={RenderTextarea}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card h-auto ">
                    <div className="card-header">
                      <h3>Shipping Address</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <Field
                          name="shippingVatId"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="shippingVatId"
                          labelClass="form-label"
                          label="Shipping Vat Id"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="shippingAddress"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="shippingaddress"
                          labelClass="form-label"
                          label="Shipping Address"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="partyName"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="partyname"
                          labelClass="form-label"
                          label="Shipping Person Party"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="shippingPinCode"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="shippingpincode"
                          labelClass="form-label"
                          label="Shipping PinCode"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="shippingContactPerson"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="shippingcontactperson"
                          labelClass="form-label"
                          label="Shipping Contect Person"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="shippingCountry"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="shippingcountry"
                          labelClass="form-label"
                          label="Shipping Country"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="shippingState"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="shippingState"
                          labelClass="form-label"
                          label="Shipping State"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="shippingCity"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="shippingcity"
                          labelClass="form-label"
                          label="Shipping City"
                          inputClass="form-control"
                          component={RenderField}
                        />
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="card h-auto">
                    <div className="mb-3 text-end">
                      <button
                        type="button"
                        className="flex-fill btn btn-success text-white text-decoration-none"
                        onClick={() => {
                          showAddItemModal();
                          setInit(
                            !values.customerSellingInvoiceDetail
                              ? { ...values, customerSellingInvoiceDetail: [] }
                              : values
                          );
                        }}
                      >
                        Add Item
                      </button>
                    </div>
                    <div className="card-body">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              Items{" "}
                              <span
                                className="d-inline-block invisible"
                                style={{ width: "50px" }}
                              ></span>
                            </th>
                            <th>Prodect Code</th>
                            <th>
                              Unit{" "}
                              <span
                                className="d-inline-block invisible"
                                style={{ width: "50px" }}
                              ></span>
                            </th>
                            <th>Discount</th>
                            <th>DT</th>
                            <th>
                              Qty{" "}
                              <span
                                className="d-inline-block invisible"
                                style={{ width: "50px" }}
                              ></span>
                            </th>
                            <th>
                              Price{" "}
                              <span
                                className="d-inline-block invisible"
                                style={{ width: "50px" }}
                              ></span>
                            </th>
                            <th>
                              Tax %{" "}
                              <span
                                className="d-inline-block invisible"
                                style={{ width: "50px" }}
                              ></span>
                            </th>
                            <th>Tax Amount</th>
                            <th>Amt Without Tax</th>
                            <th>Amt With Tax</th>
                            <th>Remark</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray name="customerSellingInvoiceDetail">
                            {({ fields }) =>
                              !fields.length ? (
                                <tr>
                                  <td colSpan={12} className="text-center">
                                    No records
                                  </td>
                                </tr>
                              ) : (
                                fields.map((name, index) => (
                                  <>
                                    <tr
                                      key={`customerSellingInvoiceDetail${index}`}
                                    >
                                      <td>
                                        <Field
                                          name={`${name}.productname`}
                                          type="text"
                                          wrapperClass="mb-3"
                                          id={`${name}.productname`}
                                          inputClass="form-control"
                                          disabled
                                          component={RenderField}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.productcode`}
                                          type="text"
                                          wrapperClass="mb-3"
                                          id={`${name}.productcode`}
                                          inputClass="form-control"
                                          disabled
                                          component={RenderField}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.unitname`}
                                          type="text"
                                          wrapperClass="mb-3"
                                          id={`${name}.unitname`}
                                          labelClass="form-label"
                                          inputClass="form-control"
                                          disabled
                                          component={RenderField}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.discount`}
                                          type="text"
                                          wrapperClass=" mb-3"
                                          id={`${name}.discount`}
                                          inputClass="form-control"
                                          component={RenderField}
                                          onInputChange={(value) =>
                                            dqpChangeHandler(
                                              index,
                                              values,
                                              value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.discountPercentageorrupees`}
                                          id={`${name}.discountPercentageorrupees`}
                                          component={`select`}
                                          onChange={(value) =>
                                            dqpChangeHandler(
                                              index,
                                              values,
                                              value
                                            )
                                          }
                                        >
                                          <option value="percentage">%</option>
                                          <option value="aed">AED</option>
                                        </Field>
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.qty`}
                                          type="text"
                                          wrapperClass="mb-3"
                                          id={`${name}.qty`}
                                          inputClass="form-control"
                                          component={RenderField}
                                          onInputChange={(value) =>
                                            dqpChangeHandler(
                                              index,
                                              values,
                                              value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.amountperunit`}
                                          type="text"
                                          wrapperClass="mb-3"
                                          id={`${name}.amountperunit`}
                                          inputClass="form-control"
                                          component={RenderField}
                                          onInputChange={(value) =>
                                            dqpChangeHandler(
                                              index,
                                              values,
                                              value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.Tax`}
                                          type="text"
                                          wrapperClass="mb-3"
                                          id={`${name}.Tax`}
                                          inputClass="form-control"
                                          disabled
                                          component={RenderField}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.taxamount`}
                                          type="text"
                                          wrapperClass="mb-3"
                                          id={`${name}.taxamount`}
                                          inputClass="form-control"
                                          disabled
                                          component={RenderField}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.pricewithouttax`}
                                          type="text"
                                          wrapperClass="mb-3"
                                          id={`${name}.pricewithouttax`}
                                          inputClass="form-control"
                                          disabled
                                          component={RenderField}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.pricewithtax`}
                                          type="text"
                                          wrapperClass="mb-3"
                                          id={`${name}.pricewithtax`}
                                          inputClass="form-control"
                                          component={RenderField}
                                          onInputChange={(value) =>
                                            amountChangeHandler(
                                              index,
                                              values,
                                              value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          name={`${name}.remark`}
                                          type="text"
                                          wrapperClass="mb-3"
                                          id={`${name}.remark`}
                                          inputClass="form-control"
                                          rows={3}
                                          component={RenderTextarea}
                                        />
                                      </td>

                                      <td>
                                        <button
                                          onClick={() => fields.remove(index)}
                                          type="button"
                                          disabled={fields.length < 1}
                                          className={`flex-fill btn btn-danger text-white text-decoration-none`}
                                        >
                                          Remove
                                        </button>
                                      </td>
                                    </tr>
                                  </>
                                ))
                              )
                            }
                          </FieldArray>
                        </tbody>
                      </Table>
                      {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
                    </div>
                  </div>
                  {/*  */}
                  <div className="card h-auto">
                    <div className="card-body">
                      <div className="row">
                        <Field
                          name="paymentMode"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="paymentMode"
                          labelClass="form-label"
                          label="Mode Of Payment"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <Field
                          name="paidAmount"
                          type="text"
                          wrapperClass="col-md-4 mb-3"
                          id="paidAmount"
                          labelClass="form-label"
                          label="Amount"
                          inputClass="form-control"
                          component={RenderField}
                        />
                        <div className="form-check col-md-3 mt-4 ms-5 mb-3">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="isFullyPaid"
                            id="isFullyPaid"
                            component="input"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isFullyPaid"
                          >
                            Fully Paid
                          </label>
                        </div>
                        <Field
                          name="transactionRemark"
                          wrapperClass="col-md-4 mb-3"
                          id="transactionRemark"
                          labelClass="form-label"
                          label="Transaction Remark"
                          inputClass="form-control"
                          rows={4}
                          component={RenderTextarea}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      type="sumbit"
                      disabled={submitting || pristine}
                      className="btn btn-primary me-3"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      disabled={submitting || pristine}
                      className="btn btn-primary"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
                <pre>{JSON.stringify(values, null, 4)}</pre>
              </>
            )}
          />
          <ModalWrapper
            show={showAddItem}
            handleClose={closeAddItemModal}
            onAddItem={onAddItemHandler}
            component={AddItem}
            title="Add Item"
            updateForm
          />
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  companyPartyVatData: state.sales.companyPartyVatData,
  party: state.partys.datum,
  products: state.sales.product,
  saleInvoiceEdit: state.sales.datum,
});

const mapDispatch = (dispatch) => ({
  dreadCompanyPartyVat: (p) => dispatch(readCompanyPartyVat(p)),
  drparty: (p) => dispatch(readPartyById(p)),
});

export default connect(mapState, mapDispatch)(CreateSale);

import { Table } from "react-bootstrap";

const TaxReport = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h3>Tax Report</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label>Select Month or Year</label>
                  <input type="month" className="form-control" />
                </div>

                <div className="col-md-4 mb-3 ">
                  <label>Tax Amount</label>
                  <input type="text" className="form-control" onChange={{}} />
                </div>
                <div className="col-md-4 mb-3">
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={{}}
                  >
                    Clear All
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={{}}
                  >
                    Excel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={{}}
                  >
                    PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <Table>
                  <thead>
                    <tr>
                      <th>Invoice Date</th>
                      <th>Invoice No</th>
                      <th>Product Name</th>
                      <th>Party Vat Id</th>
                      <th>Tax %</th>
                      <th>Tax Amount</th>
                      <th>Prodect Code</th>
                      <th>Taxable Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TaxReport;

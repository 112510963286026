import { Dropdown, Table } from "react-bootstrap";

const ManageQuatation = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h3>Manage Quotation</h3>
            </div>
            <div className="card-body">
              <Table responsive className="w-100">
                <thead>
                  <tr>
                    <th>Quatation Number</th>
                    <th>Party Name</th>
                    <th>Company Name</th>
                    <th>Quatation Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>26</td>
                    <td>jack</td>
                    <td>Company</td>
                    <td>6/6/2024</td>
                    <td>
                      <div className="d-flex">
                        <button type="button" class="btn btn-primary me-3">
                          View
                        </button>
                        <div className="basic-dropdown">
                          <Dropdown>
                            <Dropdown.Toggle variant="primary">
                              Dropdown button
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item as="button" onClick={{}}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item as="button" onClick={{}}>
                                Detele
                              </Dropdown.Item>
                              <Dropdown.Item href="#">
                                Convert To Sale
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ManageQuatation;

import { connect } from "react-redux";
import Pdf from "./Pdf";
import { readViewSaleInvoice } from "../../../store/store";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

let ViewSaleInvoice = ({ dreadViewSaleInvoice }) => {
  const { invoiceId: csid } = useParams();

  useEffect(() => {
    console.log("csid", csid);
    dreadViewSaleInvoice({ csid });
  }, []);
  return (
    <>
      <Pdf />
    </>
  );
};

const mapDispatch = (dispatch) => ({
  dreadViewSaleInvoice: (q) => dispatch(readViewSaleInvoice(q)),
});
export default connect(null, mapDispatch)(ViewSaleInvoice);

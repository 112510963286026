import { createSlice } from "@reduxjs/toolkit";
import { readCategory } from "../thunks/readCategory";

const categorySlice = createSlice({
  name: "categories",
  initialState: {
    data: [],
    datum: {},
    subCatData: [],
    subCatDatum: {},
    isLoading: false,
    error: null,
  },
  reducers: {
    setCategory(state, action) {
      state.datum = action.payload;
    },

    setSubCategoryData(state, action) {
      state.subCatData = action.payload;
    },

    setSubCategory(state, action) {
      state.subCatDatum = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(readCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(readCategory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setCategory, setSubCategory, setSubCategoryData } =
  categorySlice.actions;

export default categorySlice.reducer;

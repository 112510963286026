import Select from "react-select";

const RenderSelect = ({
  input,
  type,
  wrapperClass,
  labelClass,
  id,
  label,
  inputClass,
  meta: { touched, error },
  options,
  isSearchable = false,
  onSelectChange,
}) => (
  <div className={wrapperClass}>
    {label && (
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
    )}

    <Select
      {...input}
      type={type}
      isSearchable={isSearchable}
      isClearable
      options={options}
      onBlur={() => input.onBlur()}
      onChange={(v) => {
        input.onChange(v);
        onSelectChange && onSelectChange(v);
      }}
      className={inputClass}
    />

    {/* Validation error messages */}
    {touched && error && <span className="text-danger">{error}</span>}
  </div>
);

export default RenderSelect;

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { readPartyById } from "../../../store/store";
import CreateParty from "./CreateParty";

const EditParty = ({ dreadParty }) => {
  const { bid, bpid } = useParams();

  useEffect(() => {
    dreadParty({ bid, bpid });
  }, []);

  return (
    <>
      <CreateParty updateForm />
    </>
  );
};

const mapState = null;

const mapDispatch = (dispatch) => ({
  dreadParty: (p) => dispatch(readPartyById(p)),
});

export default connect(mapState, mapDispatch)(EditParty);

import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { RouterProvider } from "react-router-dom";
import { router } from "./jsx/routes";
/// Style

import "./css/style.css";

function App() {
  return (
    <>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <ToastContainer />
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
}

export default App;

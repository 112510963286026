import { createSlice } from "@reduxjs/toolkit";
import {
  readCompanyPartyVat,
  readListSaleIvoice,
  readListSaleIvoiceById,
  readViewSaleInvoice,
} from "../thunks/readSale";

const saleSlice = createSlice({
  name: "sale",
  initialState: {
    companyPartyVatData: [],
    listSaleIvoice: [],
    data: [],
    datum: {},
    product: {},
    isLoading: false,
    error: null,
  },
  reducers: {
    setproduct(state, action) {
      state.product = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(readCompanyPartyVat.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readCompanyPartyVat.fulfilled, (state, action) => {
      state.isLoading = false;
      state.companyPartyVatData = action.payload;
    });
    builder.addCase(readCompanyPartyVat.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(readListSaleIvoice.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readListSaleIvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.listSaleIvoice = action.payload;
    });
    builder.addCase(readListSaleIvoice.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(readListSaleIvoiceById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readListSaleIvoiceById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.datum = action.payload;
    });
    builder.addCase(readListSaleIvoiceById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(readViewSaleInvoice.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readViewSaleInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.datum = action.payload;
    });
    builder.addCase(readViewSaleInvoice.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { setproduct } = saleSlice.actions;
export default saleSlice.reducer;

import { Table } from "react-bootstrap";
import SearchBar from "../SearchBar";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { readUser } from "../../../store/store";
import { create, read } from "../../../services/api";

const ManageUser = ({ dreadBranchUser, branchUser }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const editUser = (items) => {
    const { id } = items;
    console.log(id);
    navigate(`/editBranchUser/${id}`);
  };

  useEffect(() => {
    dreadBranchUser();
  }, []);

  const inactiveUser = async (item) => {
    const { id: ubid, status } = item;
    await read("BranchUserGet/status_change_branch_user", {
      ubid,
      status: status === "Active" ? false : true,
    });
    dreadBranchUser();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h3>Manage User</h3>
            </div>
            <div className="card-body">
              <SearchBar
                wrapperClass="col-md-10 mb-3"
                placeholder="Search here..."
                label="Search here"
                searchText={search}
                onSearchChange={onSearchChange}
              />
              <Table responsive className="w-100">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Mobile</th>
                    <th>Created On:</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!!branchUser &&
                    branchUser
                      .filter((items) =>
                        search.toLowerCase() === ""
                          ? items
                          : items.userName.toLowerCase().includes(search)
                      )
                      .map((item, index) => (
                        <tr key={`id${index}`}>
                          <td>{item.userName}</td>
                          <td>{item.mobileNo}</td>
                          <td>{item.createdon.split("T")?.[0]}</td>
                          <td>{item.status}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-primary me-3"
                              onClick={() => editUser(item)}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className={
                                item.status === "Active"
                                  ? "btn btn-primary"
                                  : "btn btn-danger"
                              }
                              onClick={() => inactiveUser(item)}
                            >
                              {item.status === "Active" ? "Inactive" : "Active"}
                            </button>
                          </td>
                        </tr>
                      ))}
                  {!branchUser && (
                    <tr>
                      <td>
                        <td>No records found...</td>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapState = (state) => ({
  branchUser: state.users.data.branchUsersData,
});
const mapDispatch = (dispatch) => ({
  dreadBranchUser: (p) => dispatch(readUser(p)),
});
export default connect(mapState, mapDispatch)(ManageUser);

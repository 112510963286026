import { Dropdown, Table } from "react-bootstrap";

const ManagePurchaseInvoice = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3>Manage Purchase Invoice </h3>
        </div>
        <div className="card-body">
          <div className="row"></div>
          <Table responsive className="w-100">
            <thead>
              <tr>
                <th>Invoic No</th>
                <th>Party Name</th>
                <th>Company Name</th>
                <th>Invoice date</th>
                <th>Invoice Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mark</td>
                <td>mark</td>
                <td>Company</td>
                <td>34</td>
                <td>2/5/34</td>

                <td>
                  <div className="d-flex">
                    <button type="button" className="btn btn-primary me-3">
                      View
                    </button>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary">
                        Dropdown button
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={{}}>
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item as="button" onClick={{}}>
                          Cancel
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default ManagePurchaseInvoice;

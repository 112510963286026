import { Field, Form } from "react-final-form";
import RenderField from "../../components/renderField/RenderField";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import Model from "../ModalWrapper";
import AddItem from "../Sale/AddItem";
import SearchBar from "../SearchBar";
import { readParty } from "../../../store/store";
import { connect } from "react-redux";
import RenderAsyncSelect from "../../components/renderField/RenderAsyncSelect";
import ReactSelect from "react-select";

const Quatation = ({ dreadParty, partys }) => {
  console.log("partys", partys);

  const [showItem, setShowItem] = useState(false);
  const [_humanResources, setHumanResources] = useState([]);

  const openItem = () => {
    setShowItem(true);
  };

  const closeItem = () => {
    setShowItem(false);
  };

  useEffect(() => {
    dreadParty();
  }, []);

  // const filterDrivers = (inputValue) => {
  //   return _humanResources
  //     .filter(
  //       (i) =>
  //         i.first_name.toLowerCase().includes(inputValue.toLowerCase()) ||
  //         i.last_name.toLowerCase().includes(inputValue.toLowerCase())
  //     )
  //     .map((item) => ({
  //       value: item.id,
  //       label: item.id + " - " + item.first_name + " " + item.last_name,
  //     }));
  // };

  const promiseOptions = async (inputValue) => {
    const customers = await partys;
    console.log(customers);

    let res = customers.filter((item) =>
      item.companyname
        .toLocaleLowerCase()
        .includes(inputValue.toLocaleLowerCase())
    );
    console.log(res);

    return res;
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Form
            initialValues={{}}
            // validate={{}}
            onSubmit={{}}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="card">
                  <div className="card-header">
                    <h3>Quatation</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <Field
                          name="iscash"
                          component="input"
                          type="checkbox"
                          value="true"
                          className="form-check-input"
                          id="iscash"
                        />
                        <label className="form-check-label" htmlFor="iscash">
                          New Customer
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="row">
                          <Field
                            name="companyName"
                            wrapperClass="col mb-3"
                            labelClass="form-label"
                            label="Search by company"
                            loadOptions={promiseOptions}
                            component={RenderAsyncSelect}
                          />
                          <Field
                            name="partyName"
                            type="text"
                            wrapperClass="col mb-3"
                            id="partyName"
                            labelClass="form-label"
                            label="Party Name"
                            inputClass="form-control"
                            component={RenderField}
                          />
                        </div>
                        <div className="row">
                          <Field
                            name="vatId"
                            type="text"
                            wrapperClass="col mb-3"
                            id="vatId"
                            labelClass="form-label"
                            label="Vat Id"
                            inputClass="form-control"
                            component={RenderField}
                          />
                          <Field
                            name="billingAddress"
                            type="text"
                            wrapperClass="col mb-3"
                            id="billingAddress"
                            labelClass="form-label"
                            label="Bill Address"
                            inputClass="form-control"
                            component={RenderField}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="div card">
                          <div className="card-body border border-2">
                            <div>
                              <Field
                                name="quotationNo"
                                type="text"
                                wrapperClass="col mb-3"
                                id="quotationNo"
                                labelClass="form-label"
                                label="Quotation No"
                                inputClass="form-control"
                                component={RenderField}
                              />
                              <Field
                                name="invoiceDate"
                                type="date"
                                wrapperClass="col mb-3"
                                id="invoiceDate"
                                labelClass="form-label"
                                label="Date"
                                inputClass="form-control"
                                //   placeholder="Enter issuedate here..."
                                component={RenderField}
                              />
                              <Field
                                name="remark"
                                type="text"
                                wrapperClass="col mb-3"
                                id="remark"
                                labelClass="form-label"
                                label="Remark"
                                inputClass="form-control"
                                component={RenderField}
                              />
                              <Field
                                name="dueDate"
                                type="date"
                                wrapperClass="col mb-3"
                                id="dueDate"
                                labelClass="form-label"
                                label="Expiry Date"
                                inputClass="form-control"
                                placeholder="Enter Expiry Date..."
                                component={RenderField}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
          <div className="card">
            <div className="card-body">
              <Table responsive className="w-100">
                <thead>
                  <tr>
                    <th>Items</th>
                    <th>Prodect Code</th>
                    <th>Unit</th>
                    <th>Price</th>
                    <th>Tax</th>
                    <th>Tax Amt</th>
                    <th>Amt W/O Tax</th>
                    <th>Amt with Tax</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>26</td>
                    <td>jack</td>
                    <td>Company</td>
                    <td>6/6/2024</td>
                    <td>6/6/2024</td>
                    <td>6/6/2024</td>
                    <td>6/6/2024</td>
                    <td>6/6/2024</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary me-3"
                        onClick={() => openItem()}
                      >
                        Add Item
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Model
                show={showItem}
                handleClose={closeItem}
                component={AddItem}
                updateForm={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  partys: state.partys.data,
});

const mapDispatch = (dispatch) => ({
  dreadParty: (p) => dispatch(readParty(p)),
});

export default connect(mapState, mapDispatch)(Quatation);

import Joi from "joi";

export const joiLoginSchema = Joi.object({
  username: Joi.string().required(),
  password: Joi.string().required(),
}).unknown();

export const joiRegisterSchema = Joi.object({
  firstname: Joi.string().required(),
  mobile: Joi.string().required(),
  email: Joi.string().required(),
  password: Joi.string().required(),
}).unknown();

export const joiCompanySetupSchema = Joi.object({
  companyname: Joi.string().required(),
  // countrycode: Joi.number().required(),
  phone: Joi.string().required(),
  pincode: Joi.number().required(),
  vatid: Joi.string().required(),
  contactpersonname: Joi.string().required(),
  username: Joi.string().required(),
  effectiveregistrationdate: Joi.date().required(),
  currenttaxperiodmonthandyear: Joi.string().required(),
  coparatetax: Joi.alternatives().conditional("iscorpratetax", {
    is: "Yes",
    then: Joi.number().required(),
    otherwise: Joi.allow("").allow(null),
  }),
}).unknown();

export const joiBranchUserDetails = Joi.object({
  password: Joi.string().required(),
  userName: Joi.string().required(),
  mobileNo: Joi.string().required(),
  emailId: Joi.string().required(),
  designationRole: Joi.string().required(),
}).unknown();

export const joiProductDetailsSchema = Joi.object({
  producttype: Joi.object()
    .required()
    .messages({ "object.base": '"prodectType" is required' }),
  // producttype: Joi.string().required(),
  pricewithouttax: Joi.string().required(),
  productname: Joi.string().required(),
  productdetails: Joi.string().required(),
  tax: Joi.string().required(),
  productcode: Joi.string().required(),
  unitid: Joi.string().required(),
  purchaseprice: Joi.string().required(),
  saleprice: Joi.string().required(),
  // CategoryName: Joi.object().required().messages({
  //   "object.base": '"Category" is required',
  // }),
  // subcategoryname: Joi.string().required(),
  reorderlevel: Joi.string().required(),
  stockavailable: Joi.string().required(),
  remark: Joi.string().required(),
  // productimage
}).unknown();

export const joiPartySchema = Joi.object({
  partytype: Joi.object().required().messages({
    "object.base": '"party type" is required',
  }),
  vatid: Joi.string().required(),
  companyname: Joi.string().required(),
  partyname: Joi.string().required(),
  partymobile: Joi.string().required(),
  emailid: Joi.string().required(),
  billingaddress: Joi.string().required(),
  billingcountry: Joi.string().required(),
  billingstate: Joi.string().required(),
  billingcity: Joi.string().required(),
  billingpincode: Joi.number().required(),
  // iscash:Joi.string().required(),
  shippingaddress: Joi.string().required(),
  shippingcountry: Joi.string().required(),
  shippingstate: Joi.string().required(),
  shippingcity: Joi.string().required(),
  shippingpincode: Joi.number().required(),
}).unknown();

export const joiCategorySchema = Joi.object({
  categoryname: Joi.string().required(),
}).unknown();

// export const joiSalsSchema = Joi.object({
//   salesInvoiceType: Joi.object().required().messages({
//     "object.base": '"Sale type" is required',
//   }),
//   partyId: Joi.string().required(),
//   partyname: Joi.string.required(),
//   invoiceNo: Joi.string.required(),
//   invoiceDate: Joi.string.required(),
//   taxableAmount: Joi.string.required(),
//   netAmount: Joi.string.required(),
//   vatId: Joi.string.required(),
//   billingCity: Joi.string.required(),
//   billingState: Joi.string.required(),
//   billingAddress: Joi.string.required(),
//   remark: Joi.string.required(),
//   shippingvatid: Joi.string.required,
//   shippingpincode: Joi.string.required(),
//   shippingcontactperson: Joi.string.required(),
//   shippingaddress: Joi.string.required(),
//   shippingcity: Joi.string.required(),
//   shippingstate: Joi.string.required(),
//   modeOfpayment: Joi.string.required(),
//   Amount: Joi.string.required(),
//   Fullpaid: Joi.string.required(),
//   transationRemark: Joi.string.required(),
// });

import { Field, Form } from "react-final-form";
import RenderField from "../components/renderField/RenderField";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Table } from "react-bootstrap";

const ReportDayBook = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Form
            initialValues={{}}
            // validate={{}}
            onSubmit={{}}
            render={({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="card">
                  <div className="card-header">
                    <h3>Report Day Book</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Field
                        name="reportdatbook"
                        type="month"
                        wrapperClass="col-md-4 mb-3"
                        id="reportdatbook"
                        labelClass="form-label"
                        label="Month and Years"
                        inputClass="form-control"
                        placeholder="Month and Years"
                        component={RenderField}
                      />

                      <div className="col-md-4 mb-3">
                        <label className="form-label">
                          <strong>Select Data Range</strong>
                        </label>
                        <DateRangePicker
                          initialSettings={{
                            startDate: "10/5/2022",
                            endDate: "3/6/2022",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control input-daterange-timepicker"
                          />
                        </DateRangePicker>
                      </div>
                      <div className="col-md-4">
                        <button type="button" className="btn btn-primary mt-4">
                          Clear All
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
          <div className="card">
            <div className="card-header">
              <h3>Sales Amount</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Purchase Amount</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col">Paid</div>
                <div className="col">Unpaid</div>
                <div className="col">Total Amount</div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Expense Amount</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
              </div>
            </div>
          </div>
          <div className="card">
            {/* <div className="card-header">
              <h3>Sales Amount</h3>
            </div> */}
            <div className="card-body">
              <div className="row">
                <div className="col">Payment Mode</div>
                <div className="col">Credit Amount</div>
                <div className="col">Debit Amount</div>
              </div>
            </div>
          </div>
          <div className="card">
            {/* <div className="card-header">
              <h3>Sales Amount</h3>
            </div> */}
            <div className="card-body">
              <div className="row">
                <Table responsive className="w-100">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Transation Date</th>
                      <th>Company Name</th>
                      <th>Party Name</th>
                      <th>Invoice No</th>
                      <th>Transation Amount</th>
                      <th>Mop</th>
                      <th>Dr/Cr</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Mark</td>
                      <td>mark</td>
                      <td>Company</td>
                      <td>34</td>
                      <td>2/5/34</td>
                      <td>2/5/34</td>
                      <td>2/5/34</td>
                      <td>2/5/34</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReportDayBook;

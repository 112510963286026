// import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { PostsReducer, toggleMenu } from "./reducers/PostsReducer";
// import thunk from "redux-thunk";
// import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import companyReducer from "./slices/companySlice";
import countryReducer from "./slices/countrySlice";
import productReducer from "./slices/productSlice";
import categoryReducer from "./slices/categorySlice";
import partyReducer from "./slices/partySlice";
import userReducer from "./slices/userSlice";
import saleReducer from "./slices/saleSlice";
// const middleware = applyMiddleware(thunk);

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
  reducer: {
    sideMenu: toggleMenu,
    posts: PostsReducer,
    auth: authReducer,
    // auth: AuthReducer,
    todoReducers,
    company: companyReducer,
    countries: countryReducer,
    products: productReducer,
    categories: categoryReducer,
    partys: partyReducer,
    users: userReducer,
    sales: saleReducer,
  },
  //   enhancers: composeEnhancers(middleware),
});

// const reducers = combineReducers({
//   sideMenu: toggleMenu,
//   posts: PostsReducer,
//   auth: AuthReducer,
//   todoReducers,
// });

// export const store = createStore(reducers, composeEnhancers(middleware));

export * from "./thunks/readCompany";
export * from "./thunks/readProduct";
export * from "./thunks/readCategory";
export * from "./thunks/readParty";
export * from "./thunks/readUser";
export * from "./thunks/readSale";

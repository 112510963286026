import { createAsyncThunk } from "@reduxjs/toolkit";
import { read } from "../../services/api";

const readUser = createAsyncThunk("read/user", async (payload) => {
  const resp = await read("BranchUserGet/all_branch_users", payload);
  console.log(resp);
  return resp.data.value;
});
const readUserById = createAsyncThunk("read/userById", async (payload) => {
  const resp = await read("BranchUserGet/Get_BranchUser", payload);
  //   console.log("userResp", resp);
  return resp.data.value;
});

export { readUser, readUserById };

import { useState } from "react";
import { Form, Field } from "react-final-form";
import RenderField from "../../components/renderField/RenderField";
import RenderTextarea from "../../components/renderField/RenderTextarea";
// import RenderSelect from "../../components/renderField/RenderSelect";
import createValidator from "../../components/Forms/FormValidation/createValidator";
import { joiCategorySchema } from "../../components/Forms/FormValidation/joiValidationSchema";
import { create } from "../../../services/api";
import { toast } from "react-toastify";
import { readCategory } from "../../../store/store";
import { connect } from "react-redux";

const CreateSubCategory = ({
  dreadCategory,
  category,
  updateForm,
  handleClose,
  subCategory,
}) => {
  const [
    initVal,
    // setInitVal
  ] = useState(subCategory);

  //   useEffect(() => {
  //     setInitVal(category);
  //   }, [category]);

  const onSubmit = async (values) => {
    const _values = {};
    _values.categoryname = values.categoryname;
    _values.description = values.description;

    if (updateForm) {
      _values.catId = values.sno;
    } else {
      _values.parentcatid = category.sno;
    }

    try {
      await create("InventoryPost/create_category_subcategory", _values);
      toast.success(
        `Record has been ${updateForm ? "updated" : "created"} successfully...`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        }
      );

      handleClose();

      dreadCategory();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Form
        initialValues={initVal}
        validate={createValidator(joiCategorySchema)}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, values, submitting, pristine }) => (
          <>
            <form onSubmit={handleSubmit} noValidate>
              <Field
                name="categoryname"
                type="text"
                wrapperClass="mb-3"
                labelClass="form-label"
                id="categoryname"
                label="Category Name"
                inputClass="form-control"
                component={RenderField}
              />

              {/* <Field
                name="parentcatid"
                wrapperClass="mb-3"
                labelClass="form-label"
                id="parentcatid"
                label="Parent Category"
                options={categories.map((item) => ({
                  value: item.sno,
                  label: item.categoryname,
                }))}
                component={RenderSelect}
              /> */}

              <Field
                name="description"
                type="text"
                wrapperClass="mb-3"
                labelClass="form-label"
                id="description"
                label="Description"
                inputClass="form-control"
                rows={5}
                component={RenderTextarea}
              />

              <button
                type="submit"
                className="btn btn-primary"
                disabled={submitting || pristine}
              >
                Save
              </button>
            </form>
            <pre>{JSON.stringify(values, null, 4)}</pre>
          </>
        )}
      />
    </>
  );
};

const mapState = (state) => ({
  category: state.categories.datum,
  subCategory: state.categories.subCatDatum,
});

const mapDispatch = (dispatch) => ({
  dreadCategory: (p) => dispatch(readCategory(p)),
});

export default connect(mapState, mapDispatch)(CreateSubCategory);

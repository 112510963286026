const RenderField = ({
  input,
  type,
  wrapperClass,
  labelClass,
  id,
  label,
  inputClass,
  placeholder,
  onInputChange,
  disabled,
  meta: { touched, error },
}) => {
  return (
    <>
      <div className={wrapperClass}>
        <label className={labelClass} htmlFor={id}>
          <strong>{label}</strong>
        </label>
        <input
          {...input}
          // type={type}
          className={inputClass}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(v) => {
            input.onChange(v);
            onInputChange && onInputChange(v);
          }}
        />
        {touched && error && <span className="text-danger">{error}</span>}
      </div>
    </>
  );
};

export default RenderField;

const createValidator = (schema) => {
  return (values) => {
    const errors = {};

    const result = schema.validate(values, { abortEarly: false });
    if (result.error) {
      for (const item of result.error.details) {
        errors[item.context.key] = item.message;
      }
    }

    return errors;
  };
};

export default createValidator;

import { Field, Form } from "react-final-form";
import { useEffect, useState } from "react";
import RenderSelect from "../../components/renderField/RenderSelect";
import { connect } from "react-redux";
import { readProduct } from "../../../store/store";
import { setProduct } from "../../../store/slices/productSlice";

const AddItem = ({ dreadItem, products, onAddItem: addItemToList }) => {
  const [product, _setProduct] = useState({});

  useEffect(() => {
    dreadItem();
  }, [dreadItem]);

  const onChangeSelect = (e) => {
    if (e) {
      const result = products.filter((item) => item.productid === e.value)?.[0];
      _setProduct(result);
    } else {
      _setProduct({});
    }
  };

  const onSubmit = (values) => {
    console.log("product", product);
    console.log("values", values);
    if (Object.keys(product).length) {
      addItemToList(product);
    } else {
      const result =
        products.filter(
          (item) => item.productid === values.productname.value
        )?.[0] || {};

      if (Object.keys(result).length) {
        addItemToList(result);
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Form
            initialValues={{}}
            // validate={{}}
            onSubmit={onSubmit}
            render={({ handleSubmit, form, values, submitting, pristine }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <Field
                    name="productname"
                    wrapperClass="col-md-4 mb-3"
                    id="productname"
                    labelClass="form-label"
                    label="Item Name"
                    options={products.map((item) => ({
                      value: item.productid,
                      label: item.productname,
                    }))}
                    onSelectChange={(e) => onChangeSelect(e)}
                    component={RenderSelect}
                  />
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Key</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Product Code</td>
                        <td>{product?.productcode}</td>
                      </tr>
                      <tr>
                        <td>Unit</td>
                        <td>{product?.unitname}</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>{product?.productdetails}</td>
                      </tr>
                      <tr>
                        <td>Tax</td>
                        <td>{product?.tax}</td>
                      </tr>
                      <tr>
                        <td>Sale Price</td>
                        <td>{product?.saleprice}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <button type="submit" className="btn btn-primary me-3">
                    Save
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};

AddItem.defaultProps = {
  updateForm: false,
};

const mapState = (state) => ({
  products: state.products.data.manageItemData || [],
});
const mapDispatch = (dispatch) => ({
  dreadItem: (p) => dispatch(readProduct(p)),
  setProduct: (p) => dispatch(setProduct(p)),
});

export default connect(mapState, mapDispatch)(AddItem);

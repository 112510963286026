import { createSlice } from "@reduxjs/toolkit";
import { readUser, readUserById } from "../thunks/readUser";

const userSlice = createSlice({
  name: "branchUser",
  initialState: {
    data: [],
    datum: {},
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(readUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(readUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(readUserById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readUserById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.datum = action.payload;
    });
    builder.addCase(readUserById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export default userSlice.reducer;

import { Table } from "react-bootstrap";
import { Field, Form } from "react-final-form";
import RenderField from "../../components/renderField/RenderField";
import RenderTextarea from "../../components/renderField/RenderTextarea";
import RenderSelect from "../../components/renderField/RenderSelect";
import SearchBar from "../SearchBar";

const PurchaseInvoice = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Form
            initialValues={{}}
            // validate={{}}
            onSubmit={{}}
            render={({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="card h-auto">
                  <div className="card-header">
                    <h3>Purchase Invoice</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <Field
                          name="iscash"
                          component="input"
                          type="checkbox"
                          value="true"
                          className="form-check-input"
                          id="iscash"
                        />
                        <label className="form-check-label" htmlFor="iscash">
                          New Customer
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="row">
                          <SearchBar
                            wrapperClass="col mb-3"
                            placeholder="Search By Company..."
                            label="Search By Company"
                          />
                          <SearchBar
                            wrapperClass="col mb-3"
                            placeholder="Search By Party Name"
                            label="Search By Party Name"
                          />
                        </div>
                        <div className="row">
                          <SearchBar
                            wrapperClass="col mb-3"
                            placeholder="Search By Vat Id"
                            label="Search By Vat Id"
                          />
                          <Field
                            name="billingAddress"
                            type="text"
                            wrapperClass="col mb-3"
                            id="billingAddress"
                            labelClass="form-label"
                            label="Billing Address"
                            inputClass="form-control"
                            component={RenderField}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="card">
                          <div className="card-body border border-2">
                            <Field
                              name="invoiceNo"
                              type="text"
                              wrapperClass="col mb-3"
                              id="invoiceNo"
                              labelClass="form-label"
                              label="Invoice No"
                              inputClass="form-control"
                              component={RenderField}
                            />
                            <Field
                              name="dueDate"
                              type="date"
                              wrapperClass="col mb-3"
                              id="dueDate"
                              labelClass="form-label"
                              label="Invoice Date"
                              inputClass="form-control"
                              component={RenderField}
                            />
                            <Field
                              name="dueDate"
                              type="date"
                              wrapperClass="col mb-3"
                              id="dueDate"
                              labelClass="form-label"
                              label="Due Date"
                              inputClass="form-control"
                              component={RenderField}
                            />
                            <Field
                              name="remark"
                              wrapperClass="col mb-3"
                              id="remark"
                              labelClass="form-label"
                              label="Remark"
                              inputClass="form-control"
                              rows={4}
                              component={RenderTextarea}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card h-auto">
                  <div className="card-header">
                    <h3>Shipping Address</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Field
                        name="shippingAddress"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingAddress"
                        labelClass="form-label"
                        label="Shipping Address"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingPersonParty"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingPersonParty"
                        labelClass="form-label"
                        label="Party Name/Content Person"
                        inputClass="form-control"
                        component={RenderField}
                      />

                      <Field
                        name="shippingContactPerson"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingContactPerson"
                        labelClass="form-label"
                        label="Contect Mobile"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingCountry"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingCountry"
                        labelClass="form-label"
                        label="Country"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingState"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingState"
                        labelClass="form-label"
                        label="State"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingCity"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingCity"
                        labelClass="form-label"
                        label="City"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingPinCode"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingPinCode"
                        labelClass="form-label"
                        label="Pin Code"
                        inputClass="form-control"
                        component={RenderField}
                      />
                    </div>
                  </div>
                </div>

                <div className="card h-auto">
                  <div className="card-body">
                    <div className="row">
                      <Field
                        name="ModeOfpayment"
                        // type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="ModeOfpayment"
                        labelClass="form-label"
                        label="Mode of Payment"
                        // inputClass="form-control"
                        isSearchable
                        options={[
                          { value: "Cash", label: "Cash" },
                          { value: "Cheque", label: "Cheque" },
                          { value: "UPI", label: "UPI" },
                          { value: "BankTransation", label: "Bank Transation" },
                        ]}
                        component={RenderSelect}
                      />
                      <Field
                        name="Amount"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="Amount"
                        labelClass="form-label"
                        label="Amount"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <div className="col-md-4 mb-3">
                        <div className="col-md-4">
                          <Field
                            name="iscash"
                            component="input"
                            type="checkbox"
                            value="true"
                            className="form-check-input"
                            id="iscash"
                          />
                          <label className="form-check-label" htmlFor="iscash">
                            Full Paid
                          </label>
                        </div>
                      </div>
                      <Field
                        name="paymentRemark"
                        wrapperClass="col-md-4 mb-3"
                        id="paymentRemark"
                        labelClass="form-label"
                        label="Payment Remark"
                        inputClass="form-control"
                        rows={4}
                        component={RenderTextarea}
                      />
                      <div>
                        <button type="button" className="btn btn-primary me-3">
                          Save
                        </button>
                        <button type="button" className="btn btn-primary">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
          <div className="card">
            <div className="card-body">
              <Table responsive className="w-100">
                <thead>
                  <tr>
                    <th>Items</th>
                    <th>Prodect Code</th>
                    <th>Unit</th>
                    <th>Dis</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Tax</th>
                    <th>Tax Amount</th>
                    <th>Amt With Tax</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Mark</td>
                    <td>369875698</td>
                    <td>1/5/2000</td>
                    <td>check</td>
                    <td>check</td>
                    <td>check</td>
                    <td>check</td>
                    <td>check</td>
                    <td>check</td>
                    <td>
                      <button type="button" className="btn btn-primary me-3">
                        Add Item
                      </button>
                      <button type="button" className="btn btn-primary ">
                        New Item
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          Add MoreItem CustiomeField Shippingid, shippingPerson/Party
        </div>
      </div>
    </>
  );
};
export default PurchaseInvoice;

import { Form, Field } from "react-final-form";
import RenderField from "../../components/renderField/RenderField";
import createValidator from "../../components/Forms/FormValidation/createValidator";
import { joiBranchUserDetails } from "../../components/Forms/FormValidation/joiValidationSchema";
import { connect } from "react-redux";
import { create } from "../../../services/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const CreateBranchUser = ({ permission, branchUser, updateForm }) => {
  console.log("++++++++", branchUser);

  const [checkValue, setCheck] = useState(true);
  const [init, setinit] = useState({});

  useEffect(() => {
    const _branchUser = { ...branchUser };
    console.log("this is branch", _branchUser);
    if (updateForm) {
      const MenuSubMenuData = _branchUser?.menuSubMenuData || [];
      // console.log("menuSubMenuData", MenuSubMenuData);
      delete _branchUser.menuSubMenuData;

      for (const item of MenuSubMenuData) {
        _branchUser["permission-" + item.menuId] = true;
        if (item.subMenus.length) {
          for (const _item of item.subMenus) {
            _branchUser[
              "permission-" + item.menuId + "-" + _item.subMenuId
            ] = true;
          }
        }
      }
      // console.log("_branchUser", _branchUser);
    }
    setinit(_branchUser);
  }, [updateForm]);

  const onsubmit = async (value) => {
    try {
      let _values = { ...value };
      const MenuSubMenuData = [];
      const menu = {};
      for (const item of Object.keys(_values)) {
        if (item.includes("permission")) {
          const arr = item.split("-");
          if (arr.length === 3) {
            if (arr[1] in menu) {
              menu[arr[1]].push(arr[2]);
            } else {
              menu[arr[1]] = [];
              menu[arr[1]].push(arr[2]);
            }
          } else if (arr.length === 2) {
            if (!(arr[1] in menu)) {
              menu[arr[1]] = [];
            }
          }
          delete _values[item];
        }
      }
      for (const item of Object.keys(menu)) {
        MenuSubMenuData.push({ menuid: item, submenuid: menu[item] });
      }
      _values = { ..._values, MenuSubMenuData };

      console.log("result", _values);

      const resp = await create(
        "BranchUserPost/branch_user_registerorupdate",
        _values
      );
      if (resp.data.isSuccessful === true) {
        toast.success("Branch User Created Successfully");
      }
    } catch (error) {
      if (error.response.data.isSuccessful === false) {
        toast.warning("Branch User Already Exist");
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Form
            initialValues={init}
            validate={createValidator(joiBranchUserDetails)}
            onSubmit={onsubmit}
            mutators={{
              changeFieldValue: ([fieldName, fieldValue], state, utils) => {
                utils.changeValue(state, fieldName, () => fieldValue);
              },
              changeCurrentValue: ([fieldName, fieldValue], state, utils) => {
                utils.changeValue(state, fieldName, () => fieldValue);
              },
            }}
            render={({
              handleSubmit,
              form: {
                mutators: { push, changeFieldValue, changeCurrentValue },
              },
              submitting,
              values,
              pristine,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="card h-auto">
                  <div className="card-header">
                    <h3>Create Branch User</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Field
                        name="userName"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="companyname"
                        labelClass="form-label"
                        label="User Name"
                        inputClass="form-control"
                        // placeholder="User Name"
                        component={RenderField}
                      />
                      <Field
                        name="mobileNo"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="MobileNo"
                        labelClass="form-label"
                        label="Mobile No"
                        inputClass="form-control"
                        // placeholder="Mobile No"
                        component={RenderField}
                      />
                      <Field
                        name="password"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="Password"
                        labelClass="form-label"
                        label="Password"
                        inputClass="form-control"
                        // placeholder="Password"
                        component={RenderField}
                      />
                      <Field
                        name="emailId"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="EmailId"
                        labelClass="form-label"
                        label="Email"
                        inputClass="form-control"
                        // placeholder="Email"
                        component={RenderField}
                      />
                      <Field
                        name="designationRole"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="DesignationRole"
                        labelClass="form-label"
                        label="Designation"
                        inputClass="form-control"
                        // placeholder="Designation"
                        component={RenderField}
                      />
                    </div>
                  </div>
                </div>
                <div className="card h-auto">
                  <div className="card-header">
                    <h3>Assign Permission</h3>
                  </div>
                  <div className="card-body">
                    {permission.length &&
                      permission.map((item, index) => (
                        <>
                          <div className="form-check mb-2" key={`per${index}`}>
                            <Field
                              name={`permission-` + item.menuId}
                              type="checkbox"
                              className="form-check-input"
                              id={item.menuId}
                              component="input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={item.menuId}
                            >
                              {item.menuName}
                            </label>
                          </div>
                          <div className="ms-5">
                            {!!item.subMenus.length &&
                              item.subMenus.map((_item) => (
                                <div
                                  key={`id${_item.subMenuId}`}
                                  className="form-check mb-2"
                                >
                                  <Field
                                    name={`permission-${item.menuId}-${_item.subMenuId}`}
                                    type="checkbox"
                                    className="form-check-input"
                                    id={_item.subMenuId}
                                    component="input"
                                    onChange={(e) => {
                                      setCheck(e.target.checked);
                                      changeCurrentValue(
                                        `permission-${item.menuId}-${_item.subMenuId}`,
                                        checkValue
                                      );
                                      changeFieldValue(
                                        `permission-` + item.menuId,
                                        true
                                      );
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={_item.subMenuId}
                                  >
                                    {_item.subMenu}
                                  </label>
                                </div>
                              ))}
                          </div>
                        </>
                      ))}
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    type="submit"
                    disabled={submitting || pristine}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};

CreateBranchUser.defaultProps = {
  updateForm: false,
};

const mapState = (state) =>
  // console.log(state, "============ test"),
  ({
    permission: state.auth?.auth?.value?.permission || [],
    branchUser: state.users.datum,
  });

export default connect(mapState, null)(CreateBranchUser);

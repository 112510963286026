import { Field, Form } from "react-final-form";
import RenderField from "../../components/renderField/RenderField";
import RenderTextarea from "../../components/renderField/RenderTextarea";

const UpDateStack = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Form
            initialValues={{}}
            onSubmit={{}}
            // validate={{}}
            render={({ handleSubmit, values, form, submitting, pristine }) => (
              <form onSubmit={handleSubmit} noValidate>
                {/* <div className="row"> */}
                <Field
                  name="productname"
                  type="text"
                  wrapperClass="col-12 mb-3"
                  id="productname"
                  labelClass="form-label"
                  label="Product Name"
                  inputClass="form-control"
                  component={RenderField}
                />
                <Field
                  name="productdetails"
                  type="text"
                  wrapperClass="col-12 mb-3"
                  id="productdetails"
                  labelClass="form-label"
                  label="Product Details"
                  inputClass="form-control"
                  rows={5}
                  component={RenderTextarea}
                />
                {/* </div> */}
                <div className="d-flex">
                  <button
                    type="sumbit"
                    disabled={submitting || pristine}
                    className="btn btn-primary me-2"
                  >
                    Save
                  </button>
                  <button
                    type="sumbit"
                    disabled={submitting || pristine}
                    className="btn btn-primary"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};
export default UpDateStack;

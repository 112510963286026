import { Field, Form } from "react-final-form";
import RenderSelect from "../../components/renderField/RenderSelect";
import RenderField from "../../components/renderField/RenderField";
import RenderTextarea from "../../components/renderField/RenderTextarea";
import createValidator from "../../components/Forms/FormValidation/createValidator";
import { joiPartySchema } from "../../components/Forms/FormValidation/joiValidationSchema";
// import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { create } from "../../../services/api";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setParty } from "../../../store/slices/partySlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { customerOptions } from "../../../other/options";

const CreateParty = ({ party, updateForm, dsetParty }) => {
  const [init, setInit] = useState({});
  const [sameAsBillingAddr, setsameAsBillingAddr] = useState(false);

  const navigate = useNavigate();

  const sameAsBillingAddrHandler = (e, values) => {
    console.log(values);

    setsameAsBillingAddr(e.target.checked);
    if (e.target.checked) {
      setInit({
        ...values,
        shippingaddress: values.billingaddress,
        shippingcountry: values.billingcountry,
        shippingstate: values.billingstate,
        shippingcity: values.billingcity,
        shippingpincode: values.billingpincode,
      });
    } else {
      setInit({
        ...values,
        shippingaddress: "",
        shippingcountry: "",
        shippingstate: "",
        shippingcity: "",
        shippingpincode: "",
      });
    }
  };

  useEffect(() => {
    !updateForm && dsetParty({});
  }, [updateForm]);

  useEffect(() => {
    const _party = { ...party };
    // if (!("CustomFields" in _party)) {
    //   _party["CustomFields"] = [{}];
    // }

    _party.partytype =
      customerOptions.filter((item) => item.value === _party.partytype)?.[0] ||
      null;
    setInit({ ...init, ..._party });
  }, [party]);

  const onSubmit = async (values) => {
    const _values = { ...values };

    _values.partytype = _values.partytype ? _values.partytype.value : "";

    try {
      await create("PartyPost/createupdate_parties", _values);
      toast.success(
        `Record has been ${!updateForm ? "created" : "updated"} successfully`
      );
      // redirect to the listing pages
      navigate("/manage_party");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Form
            initialValues={init}
            validate={createValidator(joiPartySchema)}
            onSubmit={onSubmit}
            mutators={{ ...arrayMutators }}
            render={({
              handleSubmit,
              form: {
                mutators: { push },
              },
              submitting,
              values,
              pristine,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="card h-auto">
                  <div className="card-header">
                    <h3>{updateForm ? "Edit Party" : "Party"}</h3>
                  </div>
                  <div className="card-body">
                    <div className="row border-bottom mb-3 pb-3">
                      <Field
                        name="partytype"
                        wrapperClass="col-md-4 mb-3"
                        id="partytype"
                        labelClass="form-label"
                        label="Choose Party"
                        isSearchable
                        options={customerOptions}
                        component={RenderSelect}
                      />
                    </div>
                    <div className="row">
                      <Field
                        name="vatid"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="vatid"
                        labelClass="form-label"
                        label="Vat Id"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="companyname"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="companyname"
                        labelClass="form-label"
                        label="Company Name"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="partyname"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="partyname"
                        labelClass="form-label"
                        label="Party Name"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="partymobile"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="partymobile"
                        labelClass="form-label"
                        label="Mobile"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="emailid"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="emailid"
                        labelClass="form-label"
                        label="Email"
                        inputClass="form-control"
                        component={RenderField}
                      />
                    </div>
                  </div>
                </div>
                <div className="card card h-auto">
                  <div className="card-header">
                    <h3>Address Details</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Field
                        name="billingaddress"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="billingaddress"
                        labelClass="form-label"
                        label="Address"
                        inputClass="form-control"
                        rows={4}
                        component={RenderTextarea}
                      />
                      <Field
                        name="billingcountry"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="billingcountry"
                        labelClass="form-label"
                        label="Country"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="billingstate"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="billingstate"
                        labelClass="form-label"
                        label="State"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="billingcity"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="billingcity"
                        labelClass="form-label"
                        label="City"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="billingpincode"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="billingpincode"
                        labelClass="form-label"
                        label="PinCode"
                        inputClass="form-control"
                        component={RenderField}
                      />
                    </div>
                  </div>
                </div>
                <div className="card card h-auto">
                  <div className="card-header">
                    <h3>Shipping Detalis</h3>
                  </div>
                  <div className="card-body">
                    <div className="row border-bottom mb-3 pb-3">
                      <div className="col-md-4 mb-3">
                        {/* <Field
                          name="iscash"
                          component="input"
                          type="checkbox"
                          value="true"
                          className="form-check-input"
                          id="iscash"
                        /> */}
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="sameAsBillingAddr"
                          checked={sameAsBillingAddr}
                          onChange={(e) => sameAsBillingAddrHandler(e, values)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="sameAsBillingAddr"
                        >
                          Same As Billing Address
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <Field
                        name="shippingaddress"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingaddress"
                        labelClass="form-label"
                        label="Address"
                        inputClass="form-control"
                        rows={4}
                        component={RenderTextarea}
                      />
                      <Field
                        name="shippingcountry"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingcountry"
                        labelClass="form-label"
                        label="Country"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingstate"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingstate"
                        labelClass="form-label"
                        label="State"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingcity"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingcity"
                        labelClass="form-label"
                        label="City"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      <Field
                        name="shippingpincode"
                        type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="shippingpincode"
                        labelClass="form-label"
                        label="PinCode"
                        inputClass="form-control"
                        component={RenderField}
                      />
                      {/* <div className="row">
                        <FieldArray name="CustomFields">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <div
                                key={`ProductDetails-CustomFields-${index.toString()}`}
                                className="mb-3 row"
                              >
                                <Field
                                  name={`${name}.detailName`}
                                  type="text"
                                  wrapperClass="col-md-5 mb-3"
                                  id={`${name}.detailName`}
                                  labelClass="form-label"
                                  label={`${name}.detailName`}
                                  inputClass="form-control"
                                  //   placeholder="Enter otherDetails.detailname here..."
                                  component={RenderField}
                                />
                                <Field
                                  name={`${name}.detailValue`}
                                  type="text"
                                  wrapperClass="col-md-5 mb-3"
                                  id={`${name}.detailValue`}
                                  labelClass="form-label"
                                  label={`${name}.detailValue`}
                                  inputClass="form-control"
                                  //   placeholder="Enter otherDetails.detailvalue here..."
                                  component={RenderField}
                                />

                                <div className="col-md-2 d-flex align-items-center">
                                  <button
                                    onClick={() => fields.remove(index)}
                                    type="button"
                                    disabled={fields.length < 2}
                                    className={`flex-fill btn btn-success text-white text-decoration-none`}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            ))
                          }
                        </FieldArray>
                        {values.CustomFields &&
                          values.CustomFields.length < 5 && (
                            <div className="text-end mb-3">
                              <button
                                onClick={() => push("CustomFields", undefined)}
                                type="button"
                                className={`flex-fill btn btn-link text-success text-decoration-none`}
                              >
                                Add other custom fields
                              </button>
                            </div>
                          )}
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    type="sumbit"
                    disabled={submitting || pristine}
                    className="btn btn-primary"
                  >
                    Save
                  </button>
                </div>

                <pre>{JSON.stringify(values, null, 4)}</pre>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  party: state.partys.datum,
});

const mapDispatch = (dispatch) => ({
  dsetParty: (p) => dispatch(setParty(p)),
});

export default connect(mapState, mapDispatch)(CreateParty);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { read } from "../../services/api";

const readProduct = createAsyncThunk("read/product", async (payload) => {
  const resp = await read("InventoryGet/GetProducts", payload);
  return resp.data.value;
});

const readProductById = createAsyncThunk(
  "read/productById",
  async (payload) => {
    const resp = await read("InventoryGet/Get_Product_by_prodid", payload);
    return resp.data.value;
  }
);

export { readProduct, readProductById };

export const customerOptions = [
  { value: "customer", label: "Customer" },
  { value: "supplier", label: "Supplier" },
  { value: "both", label: "Both" },
];

export const productOptions = [
  { value: "product", label: "Product" },
  { value: "service", label: "Service" },
];
export const checkProductOption = [
  { value: "productimage", label: "Image" },
  { value: "categoryid", label: "Category" },
  { value: "productcode", label: "Product Code" },
  { value: "unitname", label: "Unit" },
];

export const salesInvoiceOption = [
  { value: "Tax Invoice", label: "Tax Invoice" },
  {
    value: "Bill of Supply",
    label: "Bill of Supply",
  },
  { value: "Job Work", label: "Job Work" },
  { value: "Cash Invoice", label: "Cash Invoice" },
];

import { useParams } from "react-router-dom";
import ProductDetails from "./ProductDetails";
import { useEffect } from "react";
import { connect } from "react-redux";
import { readProductById } from "../../../store/store";

const EditProduct = ({ dreadProdect }) => {
  const { prodId } = useParams();
  console.log("prodId", prodId);

  useEffect(() => {
    dreadProdect({ prodId });
  }, []);
  return (
    <>
      <ProductDetails updateForm />
    </>
  );
};

const mapState = null;
const mapDispatch = (dispatch) => ({
  dreadProdect: (p) => dispatch(readProductById(p)),
});
export default connect(mapState, mapDispatch)(EditProduct);

import { useParams } from "react-router-dom";
import CreateBranchUser from "./CreateBranchUser";
import { useEffect } from "react";
import { connect } from "react-redux";
import { readUserById } from "../../../store/store";

const EditBranchUser = ({ dreadBranchUser }) => {
  const { ubid } = useParams();
  console.log(ubid);

  useEffect(() => {
    dreadBranchUser({ ubid });
  }, []);

  return (
    <>
      <CreateBranchUser updateForm />
    </>
  );
};
const mapDispatch = (dispatch) => ({
  dreadBranchUser: (q) => dispatch(readUserById(q)),
});
export default connect(null, mapDispatch)(EditBranchUser);

import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import RenderField from "../components/renderField/RenderField";
import {
  loadingToggleAction,
  loginAction,
  _loginAction,
} from "../../store/actions/AuthActions";
import { _login } from "../../services/AuthService";
// image
import rainbow from "../../images/rainbow.gif";
import createValidator from "../components/Forms/FormValidation/createValidator";
import { joiLoginSchema } from "../components/Forms/FormValidation/joiValidationSchema";

import { auth } from "../../store/thunks/auth";
import { _isAuthenticated } from "../../store/selectors/AuthSelectors";

function Login(props) {
  const { dauth, auth } = props;

  // const [email, setEmail] = useState("demo@demo.com");
  // let errorsObj = { email: "", password: "" };
  // const [errors, setErrors] = useState(errorsObj);
  // const [password, setPassword] = useState("123456");

  // const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   _isAuthenticated() ? Nav("/Company_setup") : undefined;
  // }, [_isAuthenticated]);

  useEffect(() => {
    if (_isAuthenticated()) {
      navigate("/Company_setup");
    }
  }, [navigate, auth]);

  const onSubmit = async (values) => {
    try {
      dauth(values);
      // console.log("Company_setup");
      // Nav("/Company_setup");
    } catch (error) {
      console.log(error);
    }
  };

  // function onLogin(e) {
  //   e.preventDefault();
  //   let error = false;
  //   const errorObj = { ...errorsObj };
  //   if (email === "") {
  //     errorObj.email = "Email is Required";
  //     error = true;
  //   }
  //   if (password === "") {
  //     errorObj.password = "Password is Required";
  //     error = true;
  //   }
  //   setErrors(errorObj);
  //   if (error) {
  //     return;
  //   }
  //   dispatch(loadingToggleAction(true));
  //   dispatch(loginAction(email, password, Nav));
  // }

  return (
    <div className="login-account">
      <div className="row h-100">
        <div className="col-lg-6 align-self-start">
          <div
            className="account-info-area"
            style={{ backgroundImage: "url(" + rainbow + ")" }}
          >
            <div className="login-content">
              <p className="sub-title">
                Log in to your admin dashboard with your credentials
              </p>
              <h1 className="title">
                The Evolution of <span>MotaAdmin</span>
              </h1>
              <p className="text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-7 col-sm-12 mx-auto align-self-center">
          <div className="login-form">
            <div className="login-head">
              <h3 className="title">Welcome Back</h3>
              <p>
                Login page allows users to enter login credentials for
                authentication and access to secure content.
              </p>
            </div>
            <h6 className="login-title">
              <span>Login</span>
            </h6>
            {props.errorMessage && (
              <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                {props.errorMessage}
              </div>
            )}
            {props.successMessage && (
              <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                {props.successMessage}
              </div>
            )}
            <Form
              initialValues={{
                username: "newusername",
                password: "1234567",
              }}
              validate={createValidator(joiLoginSchema)}
              onSubmit={onSubmit}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <Field
                    name="username"
                    type="text"
                    wrapperClass="mb-4"
                    id="username"
                    labelClass="mb-1 text-dark"
                    label="Username/Email/Mobile"
                    inputClass="form-control form-control-lg"
                    placeholder="Username/Email/Mobile"
                    component={RenderField}
                  />

                  <Field
                    name="password"
                    type="password"
                    wrapperClass="mb-4"
                    id="password"
                    labelClass="mb-1 text-dark"
                    label="Password"
                    inputClass="form-control form-control-lg"
                    placeholder="Password"
                    component={RenderField}
                  />
                  <div className="text-center mb-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={submitting || pristine}
                    >
                      Sign Me In
                    </button>
                  </div>
                  <p className="text-center">
                    Not registered?
                    <Link
                      to={"/page-register"}
                      className="btn-link text-primary"
                    >
                      {" "}
                      Register
                    </Link>
                  </p>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => (
  console.log(state.auth),
  {
    auth: state.auth,
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  }
);

const mapDispatch = (dispatch) => ({
  dauth: (p) => dispatch(auth(p)),
});

export default connect(mapStateToProps, mapDispatch)(Login);

const SearchBar = (props) => {
  const { wrapperClass, onSearchChange, searchText, placeholder, label } =
    props;

  return (
    <>
      <div className={wrapperClass}>
        <label>
          <strong>{label}</strong>
        </label>
        <input
          className="form-control"
          type="search"
          placeholder={placeholder}
          value={searchText}
          onChange={onSearchChange}
        />
      </div>
    </>
  );
};
export default SearchBar;

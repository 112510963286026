import axiosInstance from "./AxiosInstance";

export const create = (url, data) => {
  return axiosInstance.post(url, data);
};

export const read = (url, data) => {
  const q = new URLSearchParams({
    ...data,
  }).toString();
  return axiosInstance.get(url + `?${q}`);
};

export const destroy = (url, data) => {
  const q = new URLSearchParams({
    ...data,
  }).toString();
  return axiosInstance.delete(url + `?${q}`);
};

// export function createPost(postData) {
//   return axiosInstance.post(`posts.json`, postData);
// }

// export function updatePost(post, postId) {
//   return axiosInstance.put(`posts/${postId}.json`, post);
// }

// export function deletePost(postId) {
//   return axiosInstance.delete(`posts/${postId}.json`);
// }

// export function formatPosts(postsData) {
//   let posts = [];
//   for (let key in postsData) {
//     posts.push({ ...postsData[key], id: key });
//   }

//   return posts;
// }

import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  // baseURL: `https://react-course-b798e-default-rtdb.firebaseio.com/`,
  // baseURL: `https://api.billbahi.com/api/`,
  baseURL: `https://api.unibill.ae/api/`,
  // headers: {
  //   "Content-Type": "application/json",
  // },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // const state = store.getState();
    // // const token = state.auth.auth.idToken;
    // const token = state.auth.auth.value.token;
    // config.params = config.params || {};

    // Get the token from localStorage inspite of state
    if (localStorage.hasOwnProperty("userDetails")) {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const token = userDetails?.value?.token;
      config.headers.Authorization = `Bearer ${token}`;
    }
    // config.headers.Authorization = `Bearer ${token}`;
    // config.params["Token"] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

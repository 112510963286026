import { Table } from "react-bootstrap";

const ManageExpense = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3>Manage Expense</h3>
        </div>
        <div className="card-body">
          <div className="row"></div>
          <Table responsive className="w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Expense Category</th>
                <th>Data</th>
                <th>Totle Expense</th>
                <th>Authorised By</th>
                <th>Amt Reciver</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mark</td>
                <td>mark</td>
                <td>Company</td>
                <td>34</td>
                <td>2/5/34</td>
                <td>2/5/34</td>

                <td>
                  <div className="d-flex">
                    <button type="button" className="btn btn-primary me-3">
                      View
                    </button>
                    <button type="button" className="btn btn-primary me-3">
                      Edit
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};
export default ManageExpense;

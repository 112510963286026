export const isAuthenticated = (state) => {
  if (state.auth.auth?.value?.token) return true;
  return false;
};

export const _isAuthenticated = () => {
  if (localStorage.hasOwnProperty("userDetails")) {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails?.value?.token) return true;
  }

  return false;
};

import { Table } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";

const PartyReport = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h3>Party Report</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label>Select Month or Year</label>
                  <input type="month" className="form-control" />
                </div>

                <div className="col-md-4 mb-3">
                  <label>Select Data Range</label>
                  <DateRangePicker
                    initialSettings={{
                      startDate: "10/5/2022",
                      endDate: "3/6/2022",
                    }}
                  >
                    <input
                      type="text"
                      className="form-control input-daterange-timepicker"
                    />
                  </DateRangePicker>
                </div>
                <div className="col-md-4 mb-3 ">
                  <label>Search by Party Name</label>
                  <input type="text" className="form-control" onChange={{}} />
                </div>
                <div className="col-md-4 mb-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={{}}
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Sale Report</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <Table>
                  <thead>
                    <tr>
                      <th>Invoice No</th>
                      <th>Invoice Date</th>
                      <th>Party Name</th>
                      <th>Amount</th>
                      <th>Vat Id</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={{}}
                        >
                          Clear All
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Quotation Report</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <Table>
                  <thead>
                    <tr>
                      <th>Quotation No</th>
                      <th>Party Name</th>
                      <th>Net Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={{}}
                        >
                          Clear All
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h3>Purchase Report</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <Table>
                  <thead>
                    <tr>
                      <th>Invoice No</th>
                      <th>Invoice Date</th>
                      <th>Party Name</th>
                      <th>Vat Id</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={{}}
                        >
                          Clear All
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PartyReport;

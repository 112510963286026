import { createSlice } from "@reduxjs/toolkit";
import { auth } from "../thunks/auth";
import { _saveTokenInLocalStorage } from "../../services/AuthService";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: {},
    errorMessage: "",
    successMessage: "",
    showLoading: false,
  },
  reducers: {
    setAuth(state, action) {
      state.auth = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(auth.pending, (state, action) => {
      state.showLoading = true;
    });
    builder.addCase(auth.fulfilled, (state, action) => {
      state.showLoading = false;
      state.auth = action.payload;
      state.errorMessage = "";
      state.successMessage = "Login Successfully Completed";
      _saveTokenInLocalStorage(action.payload);
    });
    builder.addCase(auth.rejected, (state, action) => {
      state.showLoading = false;
      state.errorMessage = action.error;
    });
  },
});

export const { setAuth } = authSlice.actions;
export default authSlice.reducer;

import { Field, Form } from "react-final-form";
import RenderField from "../components/renderField/RenderField";
import SearchBar from "./SearchBar";

const ManageSubCategory = () => {
  return (
    <>
      <div className="container-fluied">
        <div className="row">
          <Form
            initialValues={{}}
            // validate={{}}
            onSubmit={{}}
            render={({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="card">
                  <div className="card-header">
                    <h3>Manage Category & Sub Category</h3>
                  </div>
                  <div className="card-body">
                    <Field
                      name="addCategory"
                      type="text"
                      wrapperClass="col-md-4 mb-3"
                      id="addCategory"
                      labelClass="form-label"
                      label="Add Category"
                      inputClass="form-control"
                      component={RenderField}
                    />
                    <Field
                      name="addCategory"
                      type="text"
                      wrapperClass="col-md-4 mb-3"
                      id="addCategory"
                      labelClass="form-label"
                      label="Enter Name"
                      inputClass="form-control"
                      component={RenderField}
                    />
                    <button type="button" className="btn btn-primary me-3">
                      Save
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <SearchBar
                      wrapperClass="col-md-4 mb-3"
                      placeholder="Search By Company..."
                      label="Search here"
                    />
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};
export default ManageSubCategory;

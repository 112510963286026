import { Table } from "react-bootstrap";

const Ledger = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h3>Ledger</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <label>Select Month or Year</label>
                  <input type="month" className="form-control" />
                </div>
                <div className="col-md-4">
                  <label>Select Month</label>
                  <input type="month" className="form-control" />
                </div>
                <div className="col-md-4 mb-3">
                  <label>Search by company Name</label>
                  <input type="text" className="form-control" onChange={{}} />
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={{}}
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn btn-primary me-3"
                    onClick={{}}
                  >
                    Print New
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={{}}
                  >
                    downword PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <Table>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Particular</th>
                      <th>Type</th>
                      <th>Invoice No</th>
                      <th>Payment No</th>
                      <th>Dr</th>
                      <th>Cr</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Ledger;

import { createSlice } from "@reduxjs/toolkit";
import { readProduct, readProductById } from "../thunks/readProduct";

const productSlice = createSlice({
  name: "product",
  initialState: {
    data: {},
    datum: {},
    isLoading: false,
    error: null,
  },

  reducers: {
    setProduct(state, action) {
      state.datum = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(readProduct.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(readProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(readProductById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readProductById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.datum = action.payload;
    });
    builder.addCase(readProductById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setProduct } = productSlice.actions;
export default productSlice.reducer;

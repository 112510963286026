// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

const ModalWrapper = (props) => {
  const { show, handleClose, component: Component, title } = props;

  return (
    <>
      <Modal
        className="fade"
        show={show}
        size="lg"
        onHide={handleClose}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Component {...props} />
        </Modal.Body>
      </Modal>
    </>
  );
};

PropTypes.default = {
  title: "",
};

export default ModalWrapper;

import { createSlice } from "@reduxjs/toolkit";
import { readCompany } from "../thunks/readCompany";

const companySlice = createSlice({
  name: "company",
  initialState: {
    data: [],
    datum: {},
    isLoading: false,
    error: null,
  },
  extraReducers(builder) {
    builder.addCase(readCompany.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readCompany.fulfilled, (state, action) => {
      state.isLoading = false;
      state.datum = action.payload;
    });
    builder.addCase(readCompany.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default companySlice.reducer;

import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { readParty } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { destroy } from "../../../services/api";
import { toast } from "react-toastify";

const ManageParty = ({ partys, dreadParty }) => {
  const navigate = useNavigate();

  const editParty = (e, item) => {
    navigate(`/editParty/${item.bid}/${item.bpid}`);
  };

  const deleteItem = (item) => {
    try {
      const { bpid } = item;
      destroy("PartyPost/delete_party", bpid);
      toast.success("Record has been Deleted");
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    dreadParty({ showAll: true });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-header">
              <h3>Manage Party</h3>
            </div>
            <div className="card-body">
              <Table responsive className="w-100">
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Party Name</th>
                    <th>Vat Id</th>
                    <th>Mobile</th>
                    <th>Party Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!!partys.length &&
                    partys.map((item) => (
                      <tr>
                        <td>{item.companyname}</td>
                        <td>{item.partyname}</td>
                        <td>{item.vatid}</td>
                        <td>{item.partymobile}</td>
                        <td>{item.partytype}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary me-3"
                            onClick={(e) => editParty(e, item)}
                          >
                            Edit
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => deleteItem(item)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  {!partys.length && (
                    <tr className="text-center">
                      <td>No data found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  partys: state.partys.data,
});
const mapDispatch = (dispatch) => ({
  dreadParty: (p) => dispatch(readParty(p)),
});
export default connect(mapState, mapDispatch)(ManageParty);

import { createSlice } from "@reduxjs/toolkit";
import { readParty, readPartyById } from "../thunks/readParty";

const partySlice = createSlice({
  name: "party",
  initialState: {
    data: [],
    datum: {},
    isLoading: false,
    error: null,
  },
  reducers: {
    setParty(state, action) {
      state.datum = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(readParty.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readParty.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(readParty.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(readPartyById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(readPartyById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.datum = action.payload;
    });
    builder.addCase(readPartyById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setParty } = partySlice.actions;
export default partySlice.reducer;

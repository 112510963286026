import { connect } from "react-redux";
import "./pdf.css";
const Pdf = ({ ViewSaleInvoice }) => {
  console.log("ViewSaleInvoice", ViewSaleInvoice);

  return (
    <div className="card">
      <div className="card-body ">
        <div className="row border border-dark">
          <div className="col-3">
            <span className="fw-bolder">
              <div className="mt-4">
                <img
                  className="img-thumbnail"
                  src={ViewSaleInvoice.companyLogo}
                  alt="company Logo"
                />
              </div>
            </span>
          </div>

          <div className="col-6 text-center">
            <h1 className="card-title fs-1 lh-lg">
              {ViewSaleInvoice.companyName}
            </h1>
            <p className="fw-bold text-black ">
              {ViewSaleInvoice.companyAddress}
              <br />
              Branch Address : {} <br />
              MSME No. : {} No. : {} <br />
              Email : {} Website : {} <br />
              GSTIN: {}
            </p>
          </div>

          <div className="col-3 position-relative">
            <div className="position-absolute bottom-0 end-0 mb-3 me-2">
              <h6>
                Mobile : <span>{ViewSaleInvoice.billingMobile}</span>
              </h6>
              <h6>
                Invoice No : <span>{ViewSaleInvoice.invoiceNo}</span>
              </h6>
              <h6>
                Invoice Data :{" "}
                <span>{ViewSaleInvoice.invoiceDate?.split("T")?.[0]}</span>
              </h6>
            </div>
          </div>

          {/* <div> */}
          <div className="card-title text-center bg-light p-2">TAX INVOICE</div>
          <div className="d-flex">
            <div className="flex-grow-1  ">
              <h5>Billing Details</h5>
              <h6>
                GSTIN :&nbsp; <span>{}</span>
              </h6>
              <h6>
                State Name :&nbsp; <span>{ViewSaleInvoice.billingState}</span>
              </h6>
              <h6>
                State Code :&nbsp; <span>{ViewSaleInvoice.billingPinCode}</span>
              </h6>
            </div>

            <div className="flex-grow-1 border-start ps-3">
              <h5>Shipping Details</h5>
              <h6>
                GSTIN :&nbsp; <span>{}</span>
              </h6>
              <h6>
                State Name :&nbsp; <span>{ViewSaleInvoice.shippingState}</span>
              </h6>
              <h6>
                State Code :&nbsp;{" "}
                <span>{ViewSaleInvoice.shippingPinCode}</span>
              </h6>
            </div>

            <div className="flex-grow-1 ps-3 border-start">
              <h5>Transportation Details</h5>
              <h6>
                Transportation Gst : &nbsp; <span></span>
              </h6>
              <h6>
                Transportation Mode : &nbsp; <span></span>
              </h6>
              <h6>
                Transport Name: &nbsp;<span></span>
              </h6>

              <h6>
                Vehicle No : &nbsp;<span></span>
              </h6>
              <h6>
                Date of Supply : &nbsp;<span></span>
              </h6>
              <h6>
                Place of Supply : &nbsp;<span></span>
              </h6>
              <h6>
                GR No:&nbsp;<span>{ViewSaleInvoice.grno}</span>
              </h6>
            </div>
          </div>
          <table className="table table-bordered">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col className=" bg-light" />
              <col />
              <col className=" bg-light" />
            </colgroup>
            <thead align="center" className="card-title bg-light">
              <tr>
                <th className="th">#</th>
                <th colSpan={4}>Item Name</th>
                <th>HSN/SAC</th>
                <th>Qty</th>
                <th>Unti</th>
                <th>Price/Unti</th>
                <th>Amount</th>
                <th>GST%</th>
                <th>Net Amount</th>
              </tr>
            </thead>
            <tbody align="center">
              <tr>
                <td className="td">34</td>
                <td colSpan={4}>
                  <span>
                    {ViewSaleInvoice.sellingInvoiceDetail?.[0]?.productName}
                  </span>
                  Product Description:- {}
                </td>
                <td>4564</td>
                <td>{ViewSaleInvoice.sellingInvoiceDetail?.[0].qty}</td>
                <td>{ViewSaleInvoice.sellingInvoiceDetail?.[0].unit}</td>
                <td>
                  {ViewSaleInvoice.sellingInvoiceDetail?.[0].amountPerUnit}
                </td>
                <td>
                  {ViewSaleInvoice.sellingInvoiceDetail?.[0].priceWithTax}
                </td>
                <td>{ViewSaleInvoice.sellingInvoiceDetail?.[0].tax}</td>
                <td>{ViewSaleInvoice.sellingInvoiceDetail?.[0].totalAmount}</td>
              </tr>

              <tr>
                <td></td>
                <td colSpan={4}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={4}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={4}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={4}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={4}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <tfoot className="card-title bg-light">
              <tr align="center">
                <td colSpan={5}>Total</td>
                <td></td>
                <td>1</td>
                <td colSpan={2}></td>
                <td>324</td>
                <td>34</td>
                <td>34</td>
              </tr>
            </tfoot>
          </table>

          {/* table 2 */}

          <table className="table table-bordered">
            <thead align="center">
              <tr>
                <th>HSN/SAN</th>
                <th>Taxable Value</th>
                <th>CGST(%)</th>
                <th>CGST(Rs)</th>
                <th>SGST(%)</th>
                <th>SGST(Rs)</th>
                <th>Net Amount </th>
              </tr>
            </thead>
            <tbody align="center">
              <tr>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{54}</td>
                <td></td>
                <td>54</td>
              </tr>
            </tbody>
            <tfoot align="center" className=" bg-light">
              <tr>
                <td>Total</td>
                <td>5345</td>
                <td></td>
                <td>356</td>
                <td></td>
                <td>3456</td>
                <td>356</td>
              </tr>
            </tfoot>
          </table>

          {/* table3 */}

          <table className="table table-bordered">
            <colgroup>
              <col />
              <col className=" bg-light" />
              <col className=" bg-light" />
            </colgroup>
            <tbody>
              <tr>
                <th rowSpan={2} style={{ color: "black" }}>
                  Total Invoice Amount In Words <br />
                  Six Hundred Fourteen Rupees Only.
                </th>
                <td>TotalAmount</td>
                <td>{ViewSaleInvoice.balanceAmount}</td>
              </tr>
              <tr>
                {/* <td></td> */}
                <td>Payment Mode</td>
                <td>{ViewSaleInvoice.paymentMode}</td>
              </tr>
              <tr>
                <td rowSpan={2}>
                  <span>Bank Details</span>
                  <br />
                  <span>Account No </span> &nbsp;&nbsp;&nbsp;{" "}
                  {ViewSaleInvoice.companyAccountNo} <br />
                  <span>A/C Name</span> &nbsp;&nbsp;&nbsp;
                  {ViewSaleInvoice.partyName} <br />
                  <span>IFSC Code</span> &nbsp;&nbsp;&nbsp;{" "}
                  {ViewSaleInvoice.bankCode} <br />
                  <span>Bank name/Branch</span> {ViewSaleInvoice.bankName}
                  {" /"}
                  {ViewSaleInvoice.branchName}
                  <br />
                  <span>UPI (FOR PAYMENTS)</span>
                  &nbsp;&nbsp;&nbsp; AAAMIRHUSSAINS@OKHDFCBANK.CO <br />
                </td>
                <td>Recive Amount</td>
                <td>{ViewSaleInvoice.paymentAmount}</td>
              </tr>
              <tr>
                <td>Balance Amount</td>
                <td>{ViewSaleInvoice.balanceAmount}</td>
              </tr>

              <tr className=" bg-white">
                <th>
                  <span style={{ color: "black" }}>
                    Terms & Conditions <br /> Terms And Condition Should Be
                    According To Company
                  </span>
                </th>
                <td colSpan={2}>
                  <div className="w-25">
                    Authorised Signatory <br />
                    <img
                      className="w-25"
                      src={ViewSaleInvoice.signature}
                      alt="Signature"
                    />
                    <br />
                    For, Jain Textile Traders
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  ViewSaleInvoice: state.sales.datum,
});
export default connect(mapState, null)(Pdf);

import { useParams } from "react-router-dom";
import CreateSale from "./CreateSale";
import { useEffect } from "react";
import { readListSaleIvoiceById } from "../../../store/store";
import { connect } from "react-redux";

const EditSaleInvoice = ({ readSaleInvoice }) => {
  const { invoiceId: id } = useParams();
  console.log(id);
  useEffect(() => {
    readSaleInvoice({ id });
  }, []);
  return (
    <>
      <CreateSale updateForm />
    </>
  );
};
const mapDispatch = (dispatch) => ({
  readSaleInvoice: (q) => dispatch(readListSaleIvoiceById(q)),
});

export default connect(null, mapDispatch)(EditSaleInvoice);

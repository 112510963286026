import { Field, Form } from "react-final-form";
import RenderSelect from "../components/renderField/RenderSelect";
import RenderField from "../components/renderField/RenderField";
import { Table } from "react-bootstrap";

const CustomerPaySummary = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Form
            initialValues={{}}
            // validate={{}}
            onSubmit={{}}
            render={({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="card">
                  <div className="card-header">
                    <h3>Customer Suppalier Payment Summary</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <Field
                        name="reportdatbook"
                        type="month"
                        wrapperClass="col-md-4 mb-3"
                        id="reportdatbook"
                        labelClass="form-label"
                        label="Month and Years"
                        inputClass="form-control"
                        placeholder="Month and Years"
                        component={RenderField}
                      />
                      <Field
                        name="countryname"
                        // type="text"
                        wrapperClass="col-md-4 mb-3"
                        id="countryname"
                        labelClass="form-label"
                        label="Country Name"
                        // inputClass="form-control"
                        isSearchable
                        options={[
                          { vlaue: "both", label: "Both" },
                          { vlaue: "Supplier", label: "Supplier" },
                          { vlaue: "Customer", label: "Customer" },
                        ]}
                        component={RenderSelect}
                      />
                      <div>
                        <div className="col-md-4">
                          <button
                            type="button"
                            className="btn btn-primary me-3"
                          >
                            Excel
                          </button>
                          <button type="button" className="btn btn-primary">
                            PDF
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
          <div className="card">
            <div className="card-body">
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Type</th>
                    <th>Party Name</th>
                    <th>Company Name</th>
                    <th>VatID</th>
                    <th>Total Amt</th>
                    <th>Total Paid</th>
                    <th>Balance</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#</td>
                    <td>mick</td>
                    <td>mick</td>
                    <td>mick</td>
                    <td>mick</td>
                    <td>mick</td>
                    <td>mick</td>
                    <td>mk</td>
                    <td>
                      <button type="button" className="btn btn-primary">
                        PDF
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerPaySummary;
